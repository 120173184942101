:root {
  --ticket-mh: 117px;
  --ticket-right-w: 66px;
  --ticket-cut-w: 24px;
}

.ticket-wrap {
  --ticket-bg: var(--bg-c-3);
  --ticket-cut-bg: var(--bg-c-2);
  background-color: var(--ticket-bg);
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  min-height: var(--ticket-mh);
  border-radius: var(--br-base);
  z-index: 3;
  overflow: hidden;

  &--desktop {
    height: 100%;

    .ticket-left {
      padding-top: 22px;
    }

    .ticket-details {
      column-gap: 10px;
    }

    .ticket-details-col {
      &:first-child {
        word-break: break-word;
      }
    }
  }

  &--selected {
    --ticket-border-c: var(--primary-c-light);
    --ticket-border-w: 1px;

    .ticket-left,
    .ticket-right,
    .ticket-cut:before,
    .ticket-cut:after {
      border-color: var(--ticket-border-c);
      border-style: solid;
    }

    .ticket-cut:before, .ticket-cut:after {
      border-width: var(--ticket-border-w);
    }

    .ticket-left, .ticket-right {
      border-top-width: 1px;
      border-bottom-width: 1px;
    }

    .ticket-left {
      border-left-width: 1px;
    }

    .ticket-right {
      border-right-width: 1px;
    }
  }

  &--lg {
    --ticket-mh: 213px;
  }

  &.disabled {
    opacity: .5;
    pointer-events: none;
  }
}

.ticket-shadow-checkbox {
  &:checked {
    ~ .ticket-shadow-wrap-label {
      pointer-events: all;
    }

    ~ .ticket-shadow-wrap-holder {
      transform: rotateY(180deg);

      .ticket-shadow-wrap {
        pointer-events: none;
      }

      .ticket-shadow-wrap--backside {
        pointer-events: all;
      }
    }
  }
}

.ticket-shadow-wrap {
  position: relative;
  margin-bottom: 8px;
  --ticket-right-w: 51px;

  .ticket-left {
    padding-right: 2px;
  }

  &--backside {
    .ticket-left {
      padding-right: 15px;
      padding-left: 2px;
    }
  }

  &-label {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: none;
  }

  &-container {
    position: relative;

    .ticket-details {
      column-gap: 8px;
    }

    &--desktop {
      height: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;

      .ticket-shadow-wrap-holder {
        flex: 1;
        height: 100%;
      }

      .ticket-shadow-wrap {
        &:not(.ticket-shadow-wrap--backside) {
          height: 100%;
        }
      }
    }
  }

  &-holder {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    backface-visibility: hidden;
    transition: all 1s;
    transform: rotateY(0deg);
    transform-style: preserve-3d;

    &.disabled {
      pointer-events: none;
      opacity: .5;
    }
  }

  &--backside {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: -1;
    transform: rotateY(-180deg);
    pointer-events: none;

    .ticket-wrap {
      flex-direction: row-reverse;

      &--lg {
        height: 100%;
      }
    }

    .ticket-right {
      margin-left: unset;
      margin-right: auto;
      flex-direction: row-reverse;
    }
  }
}

.ticket-shadow {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  &--1 {
    --ticket-bg: var(--bg-c-4);
    --ticket-cut-bg: var(--bg-c-2);
    z-index: 2;
    top: 4px;
  }

  &--2 {
    --ticket-bg: var(--bg-c-5);
    --ticket-cut-bg: var(--bg-c-2);
    z-index: 1;
    top: 8px;
  }
}

.ticket {
  $self: &;
  &-badge {
    position: absolute;
    right: 85px;
    top: 0;
    z-index: 1;
    min-width: 64px;
    height: 18px;
    padding: 0 5px;
    line-height: 18px;
    font-size: var(--fs-xs);
    text-align: center;
    color: var(--text-c-1);
    border-radius: 0 0 4px 4px;

    &--accepted {
      background-color: #8BA23E;
    }

    &--callback {
      background-color: #7A7A7A;
    }

    &--declined {
      background-color: #8C3838;
    }

    &--pending {
      background-color: #B8992B;
    }
  }

  &-left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    flex: 1;
    padding: 14px 15px 14px 14px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    border-left: 1px solid transparent;
    border-radius: var(--br-base) 0 0 var(--br-base);

    app-dates:not(:last-child) {
      #{$self}-date-wrap {
        margin-bottom: 18px;
      }
    }
  }

  &-right {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    margin-left: auto;
    min-width: var(--ticket-right-w);
    flex: 0 0 var(--ticket-right-w);
    border-radius: 0 var(--br-base) var(--br-base) 0;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    border-right: 1px solid transparent;
  }

  &-cut {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    &:before, &:after {
      content: "";
      display: block;
      width: var(--ticket-cut-w);
      height: var(--ticket-cut-w);
      background-color: var(--ticket-cut-bg);
      border-radius: 50%;
    }

    &:before {
      transform: translateY(-50%);
    }

    &:after {
      transform: translateY(50%);
    }

    &-in {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: stretch;

      &:before {
        content: "";
        display: block;
        width: 4px;
        height: 100%;
        background-image: radial-gradient(
            circle at 2px 2px,
            var(--bg-c-2) 1px,
            transparent 1px,
            transparent 10px
        );
        background-repeat: repeat;
        background-size: 10px 10px;
      }
    }
  }

  &-type {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 15px;

    &-text {
      writing-mode: tb-rl;
      transform: rotate(-180deg);
      font-size: 11px;
      line-height: 1;
      font-weight: var(--fw-800);
    }
  }

  &-username {
    display: block;
    margin-bottom: 15px;
    font-size: var(--fs-lg);
    font-weight: var(--fw-700);
    line-height: var(--lh-lg);
    color: var(--text-c-1);
  }

  &-sport {
    display: block;
    margin-bottom: 8px;
    font-size: var(--fs-sm);
    font-weight: var(--fw-500);
    line-height: var(--lh-md);
    color: var(--text-c-3);
  }

  &-team {
    --ticket-team-c: var(--text-c-1);
    display: block;
    margin-bottom: 15px;
    font-size: var(--fs-md);
    font-weight: var(--fw-700);
    line-height: var(--lh-md);
    color: var(--ticket-team-c);
  }

  &-date-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    &-left {
      flex: 0;
      margin-right: 8px;
    }

    &-right {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: stretch;
    }
  }

  &-date-title {
    display: block;
    font-size: var(--fs-sm);
    font-weight: var(--fw-500);
    line-height: var(--lh-md);
    color: var(--text-c-3);
    margin-bottom: 4px;
  }

  &-date-text {
    display: block;
    font-size: var(--fs-sm);
    font-weight: var(--fw-500);
    line-height: var(--lh-md);
    color: var(--text-c-1);
    word-break: break-word;
  }

  &-details {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 25px;

    &-col {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;

      &-title {
        display: block;
        margin-bottom: 4px;
        font-size: var(--fs-sm);
        font-weight: var(--fw-500);
        line-height: var(--lh-md);
        color: var(--text-c-3);
      }

      &-info {
        display: block;
        font-size: var(--fs-sm);
        font-weight: var(--fw-700);
        line-height: var(--lh-md);
        color: var(--text-c-1);
      }
    }
  }

  &-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 8px;

    &:last-child {
      padding-top: 15px;
    }
  }

  &-qr {
    width: 112px;
    aspect-ratio: 1;
    border: 4px solid var(--primary-c);
    border-radius: var(--br-base);
    margin: auto auto 15px auto;
    overflow: hidden;

    &-text {
      display: block;
      margin-bottom: auto;
      font-size: var(--fs-sm);
      font-weight: var(--fw-500);
      line-height: var(--lh-md);
      color: var(--text-c-3);
      text-align: center;
    }
  }
}

.tickets-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
}

.tickets-col {
  .ticket-wrap {
    cursor: pointer;
  }
}
