.ticket-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  &-next {
    display: block;
    font-weight: var(--fw-500);
    font-size: var(--fs-xs);
    line-height: var(--lh-md);
    color: var(--text-c-2);
  }

  &-date {
    display: block;
    font-weight: var(--fw-500);
    font-size: var(--fs-xs);
    line-height: var(--lh-md);
    color: var(--text-c-1);
    margin-bottom: 2px;
  }

  &-title {
    display: block;
    font-weight: var(--fw-500);
    font-size: var(--fs-sm);
    line-height: var(--lh-md);
    color: var(--text-c-1);
  }

  .pill--desktop & {
    &-next {
      font-size: var(--fs-md);
      line-height: var(--lh-md);
      margin-bottom: 8px;
    }

    &-date {
      font-size: var(--fs-md);
      line-height: var(--lh-md);
      margin-bottom: 4px;
    }

    &-title {
      font-weight: var(--fw-700);
      font-size: var(--fs-md);
      line-height: var(--fs-md);
    }
  }
}
