@use "/src/bankroll-iconset/style";

html,
body,
button {
  font-family: "IBM Plex Mono", monospace;
}

.font-ibm {
  font-family: "IBM Plex Mono", monospace;
}

.font-moda {
  font-family: "Bodoni Moda", serif;
  font-style: italic;
}

:root {
  --fs-xxxxl: 48px;
  --fs-xxxl: 40px;
  --fs-xxl: 32px;
  --fs-xl: 24px;
  --fs-lg: 16px;
  --fs-md: 14px;
  --fs-sm: 12px;
  --fs-xs: 10px;

  --lh-xxxl: 58px;
  --lh-xxl: 40px;
  --lh-xl: 32px;
  --lh-lg: 20px;
  --lh-md: 16px;
  --lh-xs: 14px;
  --lh-xxs: 12px;
  --lh-xxxs: 10px;

  --fw-400: 400;
  --fw-500: 500;
  --fw-700: 700;
  --fw-800: 800;
}
