:root {
  --user-card-name-fs: 20px;
  --user-card-name-lh: 24px;
  --user-card-media-mw: 110px;
  --user-card-media-p: 10px;
  --user-card-media-img-w: 40px;
  --user-card-media-title-fs: var(--fs-sm);
  --user-card-media-title-lh: var(--lh-md);
  --user-card-media-subtitle-fs: var(--fs-md);
  --user-card-media-subtitle-lh: var(--lh-lg);
  --user-card-date-issue-fs: 8px;
  --user-card-date-issue-lh: 1;
  --user-card-date-num-fs: var(--fs-md);
  --user-card-date-num-lh: var(--lh-lg);
  --user-card-logo-h: 20px;
}

.user-card {
  position: relative;
  background-image: url(/assets/card.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  aspect-ratio: 3.26;
  border: 1px solid var(--text-c-4);
  border-radius: 16px;

  &--desktop {
    --user-card-name-fs: var(--fs-xl);
    --user-card-name-lh: var(--lh-xl);
    --user-card-media-mw: 188px;
    --user-card-media-p: 16px;
    --user-card-media-img-w: 64px;
    --user-card-media-title-fs: var(--fs-md);
    --user-card-media-title-lh: var(--lh-lg);
    --user-card-media-subtitle-fs: var(--fs-xl);
    --user-card-media-subtitle-lh: var(--lh-xl);
    --user-card-date-issue-fs: var(--fs-xs);
    --user-card-date-issue-lh: var(--lh-xs);
    --user-card-date-num-fs: var(--fs-lg);
    --user-card-date-num-lh: var(--lh-lg);
    --user-card-logo-h: 24px;
    width: 400px;
    flex: 0 0 400px;
    border-radius: 12px;
  }

  &-bg {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 16px;
  }

  &-content {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &-name {
    font-size: var(--user-card-name-fs);
    line-height: var(--user-card-name-lh);
    color: var(--text-c-1);
    font-weight: var(--fw-800);
  }

  &-media {
    min-width: var(--user-card-media-mw);
    margin-left: 10px;
    padding: var(--user-card-media-p);
    background: #4e2d0e;
    border-radius: 16px;

    &-img {
      width: var(--user-card-media-img-w);
      border-radius: var(--br-base);
      overflow: hidden;
    }

    &-title {
      display: block;
      font-size: var(--user-card-media-title-fs);
      font-weight: var(--fw-500);
      line-height: var(--user-card-media-title-lh);
      color: var(--text-c-2);
      padding-top: 10px;
    }

    &-subtitle {
      display: block;
      font-size: var(--user-card-media-subtitle-fs);
      font-weight: var(--fw-500);
      line-height: var(--user-card-media-subtitle-lh);
      color: var(--text-c-1);
      padding-top: 4px;
    }
  }

  &-date {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &-issue {
      display: block;
      font-size: var(--user-card-date-issue-fs);
      line-height: var(--user-card-date-issue-lh);
      color: var(--text-c-1);
    }

    &-num {
      display: block;
      font-size: var(--user-card-date-num-fs);
      line-height: var(--user-card-date-num-lh);
      font-weight: var(--fw-500);
      color: var(--text-c-1);
    }
  }

  &-logo {
    height: var(--user-card-logo-h);

    .logo {
      height: 100%;
      width: auto;
    }
  }

  &-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-template-rows: 30px;
  }
}
