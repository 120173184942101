:root {
  --pick-win-header-title-fs: var(--fs-md);
  --pick-win-header-title-lh: var(--lh-lg);
  --pick-win-header-subtitle-fs: var(--fs-sm);
  --pick-win-header-subtitle-lh: var(--lh-md);
  --pick-win-block-p: 12px;
  --pick-win-entries-mw: 108px;
  --pick-win-datetime-p: 4px 8px;
  --pick-win-datetime-br: 4px;
  --pick-win-datetime-title-fs: 8px;
  --pick-win-datetime-title-lh: 20px;
  --pick-win-datetime-title-p: 8px;
  --pick-win-datetime-title-m: 8px;
  --pick-win-num-fs: var(--fs-md);
  --pick-win-num-lh: var(--lh-lg);
  --pick-win-num-fw: var(--fw-700);
  --pick-win-num-w: 1px;
  --pick-win-num-h: 10px;
  --pick-win-text-fs: 8px;
  --pick-win-text-lh: 9px;
  --pick-win-text-fw: 400;
  --pick-win-num-dots-fs: var(--fs-lg);
  --pick-win-num-dots-fw: var(--fw-500);
  --pick-win-num-dots-lh: var(--lh-lg);
  --pick-win-sponsored-text-fs: var(--fs-sm);
  --pick-win-sponsored-text-lh: var(--lh-md);
  --pick-win-sponsored-name-mw: 62px;
  --pick-win-sponsored-name-fs: 8px;
  --pick-win-sponsored-name-h: 20px;
  --pick-win-sponsored-name-br: 4px;
  --pick-win-questions-title-fs: var(--fs-md);
  --pick-win-questions-title-lh: var(--lh-lg);
  --pick-win-questions-title-fw: var(--fw-700);
}

@keyframes increment-anim {
  0%,
  100% {
    transform: translate3d(3px, 0, 0);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: translate3d(3px, -100%, 0);
  }
}

.pick-win {
  &-wrap {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    &--desktop {
      --pick-win-header-title-fs: var(--fs-xl);
      --pick-win-header-title-lh: var(--lh-xl);
      --pick-win-header-subtitle-fs: var(--fs-md);
      --pick-win-header-subtitle-lh: var(--lh-md);
      --pick-win-block-p: 15px;
      --pick-win-entries-mw: 200px;
      --pick-win-datetime-p: 12px 16px;
      --pick-win-datetime-br: 8px;
      --pick-win-datetime-title-fs: var(--fs-lg);
      --pick-win-datetime-title-lh: 40px;
      --pick-win-datetime-title-p: 16px;
      --pick-win-datetime-title-m: 16px;
      --pick-win-num-fs: var(--fs-xl);
      --pick-win-num-lh: var(--lh-xl);
      --pick-win-num-fw: var(--fw-800);
      --pick-win-num-w: 2px;
      --pick-win-num-h: 16px;
      --pick-win-text-fs: var(--fs-sm);
      --pick-win-text-lh: var(--lh-md);
      --pick-win-text-fw: var(--fw-500);
      --pick-win-num-dots-fs: var(--fs-xxl);
      --pick-win-num-dots-fw: var(--fw-500);
      --pick-win-num-dots-lh: var(--lh-xxl);
      --pick-win-sponsored-text-fs: var(--fs-md);
      --pick-win-sponsored-text-lh: var(--lh-md);
      --pick-win-sponsored-name-mw: 120px;
      --pick-win-sponsored-name-fs: var(--fs-sm);
      --pick-win-sponsored-name-h: 30px;
      --pick-win-sponsored-name-br: 4px;
      min-height: 224px;
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-title {
      font-size: var(--pick-win-header-title-fs);
      line-height: var(--pick-win-header-title-lh);
    }

    &-subtitle {
      font-size: var(--pick-win-header-subtitle-fs);
      line-height: var(--pick-win-header-subtitle-lh);
    }
  }

  &-body {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }

  &-block {
    padding: var(--pick-win-block-p);
    border-radius: 8px;
    background-color: var(--bg-c-3);
    flex: 1;
    &--prize {
      padding: 8px;
    }
  }

  &-datetime {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    $dt: &;

    &-wrap {
      display: flex;
      align-items: center;
      padding: var(--pick-win-datetime-p);
      background-color: var(--bg-c-4);
      border-radius: var(--pick-win-datetime-br);

      &--prize {
        padding: 10px;
        border-radius: var(--br-base);
      }

      &--mobile {
        justify-content: center;
        align-items: stretch;
        flex-direction: column;

        #{$dt}-title {
          position: relative;
          text-align: center;
          padding-bottom: 16px;
          margin-bottom: 16px;
          padding-right: 0;
          margin-right: 0;
          border: none;

          br {
            display: none;
          }

          &:before {
            content: "";
            position: absolute;
            display: block;
            left: 50%;
            bottom: 0;
            transform: translate3d(-50%, 0, 0);
            height: 1px;
            width: 32px;
            background: var(--bg-c-7);
          }
        }
      }
    }

    &-title {
      display: block;
      font-size: var(--pick-win-datetime-title-fs);
      line-height: var(--pick-win-datetime-title-lh);
      font-weight: var(--fw-700);
      color: var(--text-c-1);
      white-space: nowrap;
      border-right: 1px solid var(--bg-c-7);
      padding-right: var(--pick-win-datetime-title-p);
      margin-right: var(--pick-win-datetime-title-m);
      &--prize{
        font-size: var(--fs-md);
        line-height: var(--lh-md);
        padding-right: 16px;
      }
    }

    &-block {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
      text-align: center;
      min-width: 32px;
      flex: 1;

      &:first-child {
        #{$dt}-num {
          &:before {
            content: "";
            width: var(--pick-win-num-w);
            height: var(--pick-win-num-h);
            background-color: var(--primary-c);
          }
        }
      }

      &:last-child {
        #{$dt}-num {
          &:before {
            display: none;
          }
        }
      }
    }

    &-num {
      position: relative;
      display: block;
      width: 100%;
      font-size: var(--pick-win-num-fs);
      line-height: var(--pick-win-num-lh);
      font-weight: var(--pick-win-num-fw);
      color: var(--primary-c);

      &:before {
        content: ":";
        display: block;
        position: absolute;
        top: 50%;
        left: 100%;
        z-index: 1;
        font-size: var(--pick-win-num-dots-fs);
        font-weight: var(--pick-win-num-dots-fw);
        line-height: var(--pick-win-num-dots-lh);
        color: var(--primary-c);
        transform: translate3d(-50%, -50%, 0);
      }
    }

    &-text {
      font-size: var(--pick-win-text-fs);
      line-height: var(--pick-win-text-lh);
      font-weight: var(--pick-win-text-fw);
      color: var(--text-c-3);
    }
  }

  &-sponsored {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-text {
      font-size: var(--pick-win-sponsored-text-fs);
      line-height: var(--pick-win-sponsored-text-lh);
    }

    &-name {
      min-width: var(--pick-win-sponsored-name-mw);
      font-size: var(--pick-win-sponsored-name-fs);
      height: var(--pick-win-sponsored-name-h);
      line-height: var(--pick-win-sponsored-name-h);
      padding: 0 10px;
      border-radius: var(--pick-win-sponsored-name-br);
      background-color: var(--bg-c-5);
    }
  }

  &-entries {
    &-wrap {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      min-width: var(--pick-win-entries-mw);
      padding: var(--pick-win-block-p);
      background-color: var(--bg-c-3);
      border-radius: 8px;
    }

    &-title {
      font-size: var(--pick-win-header-title-fs);
      line-height: var(--pick-win-header-title-lh);
    }

    &-inner {
      position: relative;
      --icon-w: 40px;
      --icon-h: 40px;
      --icon-fs: 40px;

      &--desktop {
        --icon-w: 80px;
        --icon-h: 80px;
        --icon-fs: 80px;
      }
    }

    &-counter {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 64px;
      min-height: 40px;
      padding: 4px 8px;
      background-color: var(--bg-c-4);
      border-radius: 4px;
      font-size: var(--fs-xl);
      font-weight: var(--fw-800);
      line-height: var(--lh-xl);
      color: var(--text-c-1);
      text-align: center;

      &-in {
        position: relative;
        text-align: center;
      }

      &-increment {
        position: absolute;
        left: 100%;
        top: 0;
        z-index: 1;
        opacity: 0;
        transform: translate3d(3px, 0, 0);
        will-change: opacity, transform;
        animation: increment-anim linear 1000ms forwards;
      }
    }
  }

  &-questions {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    &--desktop {
      --pick-win-questions-title-fs: var(--fs-xl);
      --pick-win-questions-title-lh: var(--lh-xl);
      --pick-win-questions-title-fw: var(--fw-800);

      .pick-win-slider-content {
        aspect-ratio: unset;
      }
    }

    &-title {
      font-size: var(--pick-win-questions-title-fs);
      line-height: var(--pick-win-questions-title-lh);
      font-weight: var(--pick-win-questions-title-fw);
    }
  }

  &-slider {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    margin: 0 calc(-1 * var(--padding-h));
    transition: transform 0.3s;
    will-change: transform;

    &-bullets {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      pointer-events: none;
      gap: 8px;
    }

    &-bullet {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      transition: all .3s;
      background-color: var(--bg-c-4);

      &--active {
        background-color: var(--primary-c);
      }
    }

    &-wrap {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      padding: var(--padding-h);
      border-radius: 8px;
      background-color: var(--bg-c-3);
      overflow: hidden;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-item {
      width: 100%;
      flex: 0 0 100%;
      padding: 0 var(--padding-h);
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }

    &--right {
      transform: translate(-50%, -50%);
      right: 0;
      top: 50%;
    }


    &--left {
      transform: translate(50%, -50%);
      left: 0;
      top: 50%;
    }



    .prize-media-pick-win {
      aspect-ratio: 364/216;
      object-fit: contain;
    }

    &-media,
    &-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: stretch;
      width: 100%;
      padding: var(--padding-h);
      background-color: var(--bg-c-2);
      border-radius: 8px;
      overflow: hidden;
      aspect-ratio: 2.192307692308;
    }

    &-media {
      &-img {
        border-radius: inherit;
      }
    }

    &-content {
      &-btns {
        margin-left: -4px;
        margin-right: -4px;

        &--desktop {
          justify-content: flex-end;
          margin-left: auto;
          max-width: 248px;
          width: 100%;
          padding-top: 36px;
        }
      }

      &-btn-block {
        flex: 1 0 0;
      }

      &-in {
        > * {
          font-size: inherit;
          font-weight: inherit;
          line-height: inherit;
          text-align: inherit;
          color: inherit;
          background-color: transparent;
        }
      }
    }

    &-empty {
    }
  }

  &-history {
    padding: var(--padding-h);
    border-radius: 8px;
    background-color: var(--bg-c-3);

    &,
    &-wrap {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
    }

    &-content {
      > * {
        font-size: inherit;
        line-height: inherit;
        text-align: inherit;
        background-color: transparent;
      }
    }

    &-col {
      --icon-w: 20px;
      --icon-h: 20px;
      --icon-fs: 20px;

      &:first-child {
        border-right: 1px solid var(--text-c-3);
      }
    }
  }
}


