:root {
}

.media-item {
  $self: &;

  &-check {
    &:checked ~ #{$self}-wrap {
      --border-color: var(--primary-c);
    }
  }

  &-wrap {
    --border-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    height: 116px;
    padding: 8px;
    background: var(--bg-c-3);
    border-radius: var(--br-base);
    overflow: hidden;
    border: 1px solid var(--border-color);
    transition: border-color 0.3s;
  }

  &-img {
    position: relative;
    min-width: 100px;
    max-width: 100px;
    width: 100px;
    height: 100%;
    border-radius: var(--br-base);
    margin-right: 15px;
  }

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }
}
