@keyframes landing-ticket-shadow-1 {
  0%,
  30%,
  100% {
    transform: translate3d(0, 0px, 0);
  }

  40%,
  90% {
    transform: translate3d(0, 5px, 0);
  }
}

@keyframes landing-ticket-shadow-2 {
  0%,
  30%,
  100% {
    transform: translate3d(0, 0px, 0);
  }

  40%,
  90% {
    transform: translate3d(0, 5px, 0);
  }

  50%,
  80% {
    transform: translate3d(0, 10px, 0);
  }
}

.landing-wrap {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background-image: url(/assets/bg-texture.webp);
  //background-size: 40px auto;
  //background-repeat: repeat;
  //background-position: center -9px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  isolation: isolate;

  //&:before {
  //  content: "";
  //  display: block;
  //  width: 100%;
  //  height: 100%;
  //  z-index: -1;
  //  position: absolute;
  //  left: 0;
  //  top: 0;
  //  background: linear-gradient(
  //    180deg,
  //    rgba(16, 16, 16, 0) 0%,
  //    rgba(16, 16, 16, 0.95) 40%,
  //    rgba(16, 16, 16, 1) 50%,
  //    #101010 100%
  //  );
  //}

  &--desktop {
    padding-top: var(--header-h);
    &:before {
      height: auto;
      top: 150px;
      bottom: 0;
      background: linear-gradient(
        180deg,
        rgba(16, 16, 16, 0) 0%,
        rgba(16, 16, 16, 0.95) 15%,
        rgba(16, 16, 16, 1) 30%,
        #101010 100%
      );
    }
  }
}

.landing {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding-bottom: 24px;
  margin-bottom: calc(-1 * var(--padding-h));

  &-tickets {
    width: 266px;
    margin-left: auto;
    margin-right: auto;

    &-wrap {
      position: relative;
      margin-bottom: 10px;
      isolation: isolate;
    }
  }
}

.landing-ticket {
  $self: &;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  &-svg {
    display: block;
    width: 100%;
    height: auto;
    pointer-events: none;
  }

  &-in {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
  }

  &-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 15px;
  }

  &-right {
    flex: 0 0 42px;
    display: flex;
    overflow: hidden;
  }

  &-qr {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    width: 46px;
    aspect-ratio: 1;
    padding: 5px;
    border: 1px solid var(--primary-c-light);
    border-radius: 4px;
  }

  &-type {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 15px;

    &-text {
      writing-mode: tb-rl;
      transform: rotate(-180deg);
      font-size: 11px;
      line-height: 1;
      font-weight: var(--fw-800);
    }
  }

  &-shadow {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    &-1 {
      z-index: -1;
      transform: translate3d(0, 0, 0);
      //animation: landing-ticket-shadow-1 linear 4000ms infinite;

      #{$self}-cutout {
        &-1 {
          stroke: var(--bg-c-5);
          fill: var(--bg-c-2);
        }

        &-2 {
          fill: var(--bg-c-5);
        }
      }
    }

    &-2 {
      z-index: -2;
      transform: translate3d(0, 0, 0);
      //animation: landing-ticket-shadow-2 linear 4000ms infinite;

      #{$self}-cutout {
        &-1 {
          stroke: var(--bg-c-5);
          fill: var(--bg-c-3);
        }

        &-2 {
          fill: var(--bg-c-5);
        }
      }
    }
  }

  &-cutout {
    &-1 {
      stroke: var(--bg-c-5);
      fill: var(--bg-c-1);
    }

    &-2 {
      fill: var(--bg-c-5);
    }
  }
}

.landing-menu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 280px;
  margin-left: auto;
  margin-right: auto;

  &-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      background: linear-gradient(
        270deg,
        rgba(161, 161, 161, 0) 9.28%,
        rgba(255, 255, 255, 0.8) 51.02%,
        rgba(161, 161, 161, 0) 90.72%
      );
    }
  }

  &-link {
    display: block;
    padding: 12px;
    font-size: var(--fs-sm);
    font-weight: var(--fw-500);
    line-height: var(--lh-md);
    color: var(--text-c-2);
    text-align: center;
  }
}

.landing-form {
  width: 265px;
  margin-left: auto;
  margin-right: auto;
}

.landing-social {
  gap: 8px;
}
