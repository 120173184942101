$anim-step-1: 63.63%; // 700ms
$anim-step-2: 81.81%; // 900ms
$anim-step-3: 100%; // 1100ms

@keyframes answer-anim-1 {
  0%,
  #{$anim-step-2} {
    transform: translate3d(0, 0, 0) scale(1);
    opacity: 1;
  }

  #{$anim-step-3} {
    transform: translate3d(0, 0, 0) scale(1.5);
    opacity: 0;
  }
}

@keyframes answer-anim-3 {
  0%,
  #{$anim-step-1} {
    transform: translate3d(0, 0, 0) scale(0.95);
  }

  #{$anim-step-2},
  #{$anim-step-3} {
    transform: translate3d(0, 0, 0) scale(0.75);
  }
}

@keyframes answer-anim-4 {
  0%,
  #{$anim-step-1} {
    transform: translate3d(0, 0, 0) scale(0.85);
  }

  #{$anim-step-3} {
    transform: translate3d(0, 0, 0) scale(0.4);
  }
}

@keyframes answer-anim-icon {
  0%,
  #{$anim-step-1} {
    transform: translate3d(0, 0, 0) scale(1);
  }

  #{$anim-step-3} {
    transform: translate3d(0, 0, 0) scale(0.46);
  }
}

@keyframes answer-anim-content {
  0%,
  86.6666666667% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  overflow: auto;

  &--image {
    background-color: #00000050 !important;

    &-in {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__block {
    display: contents;
  }
}

.popup-card-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: rgba(#000, 0.8);

  &--desktop {
    .popup-card {
      width: 480px;
      margin-left: auto;
      margin-right: auto;
      background-color: var(--bg-c-2);
      padding-left: 80px;
      padding-right: 80px;
    }

    .popup-card-btns {
      .btn {
        max-width: 160px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &--ticket {
    .popup-card {
      width: 400px;
      padding: 24px;
    }
  }
}

.popup-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 428px;
  padding: 48px 24px 24px 24px;
  background-color: var(--bg-c-3);
  border: 2px solid transparent;
  border-radius: var(--br-base);
  box-shadow: 0 4px 18px rgba(#000, 0.25);
  text-align: center;

  &--primary {
    border-color: var(--primary-c);
  }

  &--success {
    border-color: var(--c-success);
  }

  &--error {
    border-color: var(--c-error);
  }

  &--info {
    border-color: var(--c-info);
  }

  &--permission {
    border-color: var(--c-warning);

  }

  &-title {
    margin-top: 24px;
    font-size: var(--fs-xl);
    font-weight: var(--fw-800);
    line-height: var(--lh-xl);
    color: var(--text-c-1);
  }

  &-subtitle {
    margin-top: 16px;
    font-size: var(--fs-xl);
    font-weight: var(--fw-800);
    line-height: var(--lh-xl);
    color: var(--primary-c);
  }

  &-text {
    margin-top: 16px;
    font-size: var(--fs-md);
    line-height: var(--lh-lg);
    font-weight: var(--fw-500);
    color: var(--text-c-2);
  }

  &-btns {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    margin-top: auto;
    padding-top: 16px;
  }
}

.answer-popup {
  &-anim {
    position: relative;
    width: 160px;
    height: 160px;

    &-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &--win {
      --answer-layer-1-c: rgba(var(--primary-c-rgb), 0.2);
      --answer-layer-2-c: rgba(var(--primary-c-rgb), 0.3);
      --answer-layer-3-c: rgba(var(--primary-c-rgb), 0.3);
      --answer-layer-3-border-c: rgba(var(--primary-c-rgb), 0.2);
      --answer-layer-4-c: #ffffff;
      --answer-layer-4-box: unset;
      --answer-icon-c: var(--primary-c);
    }

    &--lose {
      --answer-layer-1-c: rgba(177, 10, 10, 0.2);
      --answer-layer-2-c: rgba(var(--c-error-rgb), 0.3);
      --answer-layer-3-c: rgba(var(--c-error-rgb), 0.3);
      --answer-layer-3-border-c: #b10a0a;
      --answer-layer-4-c: #ffffff;
      --answer-layer-4-box: 0 4px 4px rgba(0, 0, 0, 0.25);
      --answer-icon-c: #ac3030;
    }

    &-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 5;
      color: var(--answer-icon-c);
      --icon-w: 82px;
      --icon-h: 82px;
      --icon-fs: 82px;
      transform: translate3d(0, 0, 0) scale(1);
      animation: answer-anim-icon linear 1100ms forwards;
    }

    &-icon,
    &-layer {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      will-change: transform;
      border-radius: 50%;
      transform-origin: center center;
    }

    &-layer {
      &-1 {
        z-index: 1;
        border: 1px solid var(--answer-layer-1-c);
        transform: translate3d(0, 0, 0) scale(1);
        animation: answer-anim-1 linear 1100ms forwards;
      }

      &-2 {
        z-index: 2;
        background-color: var(--answer-layer-2-c);
        transform: translate3d(0, 0, 0) scale(1);
      }

      &-3 {
        z-index: 3;
        background-color: var(--answer-layer-3-c);
        border: 1px solid var(var(--answer-layer-3-border-c));
        transform: translate3d(0, 0, 0) scale(0.95);
        animation: answer-anim-3 linear 1100ms forwards;
      }

      &-4 {
        z-index: 4;
        background-color: var(--answer-layer-4-c);
        transform: translate3d(0, 0, 0) scale(0.85);
        box-shadow: var(--answer-layer-4-box);
        animation: answer-anim-4 linear 1100ms forwards;
      }
    }
  }

  &-content {
    opacity: 0;
    will-change: opacity;
    animation: answer-anim-content linear 1500ms forwards;
  }
}
