:root {
  --primary-c: #8c9c6d;
  --primary-c-rgb: 140, 156, 109;
  --primary-c-light: #a3b08a;
  --primary-c-light-rgb: 163, 176, 138;
  --primary-c-dark: #707d57;
  --primary-c-dark-rgb: 112, 125, 87;

  //--primary-c: #f16a22;
  //--primary-c-rgb: 241, 106, 34;
  //--primary-c-light: #f18348;
  //--primary-c-light-rgb: 241, 131, 72;
  //--primary-c-dark: #b24f19;
  //--primary-c-dark-rgb: 178, 79, 25;

  --active-c: #2ee3ef;
  --active-c-light: #a7eaef;
  --active-c-dark: #1d9299;

  --bg-c-1: #101010;
  --bg-c-2: #242424;
  --bg-c-3: #383838;
  --bg-c-4: #4d4d4d;
  --bg-c-5: #616161;
  --bg-c-5-rgb: 97, 97, 97;
  --bg-c-6: #757575;
  --bg-c-7: #8a8a8a;
  --bg-c-8: #f9f9f9;
  --bg-c-9: #751858;
  --bg-c-11: #a1a1a1;
  --bg-c-12: #4c95ea;
  --bg-c-13: #60d26d;

  --text-c-1: #f2f2f2;
  --text-c-2: #c9c9c9;
  --text-c-3: #a1a1a1;
  --text-c-3-rgb: 161, 161, 161;
  --text-c-4: #4f4f4f;
  --text-c-4-rgb: 120, 120, 120;
  --text-c-5: #787878;

  --c-gold: #c39535;
  --c-info: #4c95ea;
  --c-error: #d73434;
  --c-error-rgb: 215, 52, 52;
  --c-success: #2eefaf;
  --c-price: #6de57a;
  --c-warning: #ffc943;
}
