:root {

}

.pill {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  border-radius: var(--br-base);
  background-color: var(--bg-c-5);

  &-left {
    display: flex;
    flex: 0 0 0;
  }

  &-right {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex: 1 0 0;
    padding: 4px;
  }

  .btn--xl {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &--desktop {
    width: 32.466442953%;
    flex: 0 0 32.466442953%;
    border-radius: 12px;

    .pill-left {
      flex: 0 0 48.1865284974%;
    }
  }
}
