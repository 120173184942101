:root {
  --header-h: 56px;
  --header-btn-w: 38px;
  --header-logo-h: 32px;
  --header-bg-c: var(--bg-c-3);
}

.pt-header {
  padding-top: var(--header-h);
}

app-header {
  ~ app-breadcrumb,
  ~ * app-breadcrumb {
    .subnav {
      top: var(--header-h);
    }
  }

  ~ * .wrap, ~ .wrap {
    .subnav {
      top: var(--header-h);
    }

    .sticky--subnav {
      top: calc(var(--header-h) + var(--subnav-h));
    }
  }
}

.header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 11;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--padding-h);
  height: var(--header-h);
  min-height: var(--header-h);
  background-color: var(--header-bg-c);

  &--desktop {
    position: fixed;
    width: 100%;
    margin-bottom: var(--header-h);
    --header-logo-h: 40px;
    --header-bg-c: #000;
  }

  &-in {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-width: 76px;
  }

  + .subnav {
    top: var(--header-h);
  }

  ~ .wrap {
    .subnav {
      top: var(--header-h);
    }

    .sticky--subnav {
      top: calc(var(--header-h) + var(--subnav-h));
    }
  }

  &-btn {
    width: var(--header-btn-w);
    height: var(--header-btn-w);
  }

  &-logo {
    height: var(--header-logo-h);
  }

  &-block {
    min-width: 200px;
  }

  &-menu {
    display: flex;
    justify-content: center;
    align-items: center;

    &:has(.header-menu-item--active) {
      .header-menu-item:not(.header-menu-item--active) {
        color: var(--text-c-4);
      }
    }

    &-close {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate3d(0, -50%, 0);
    }

    &-item {
      margin: 0 32px;
      transition: all 0.3s;
      position: relative;

      &--active,
      &:hover {
        color: var(--text-c-1) !important;
      }
    }

    &-sub {
      --header-bg-c: #000;
      position: fixed;
      left: 0;
      top: var(--header-h);
      width: 100%;
      height: var(--header-h);
      min-height: var(--header-h);
      background-color: var(--header-bg-c);
      z-index: 10;
      transform: translate3d(0, -100%, 0);
      transition: transform 0.3s linear 0.3s, border-color 0.3s,
        opacity 0.3s linear 0.3s;
      will-change: transform;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top: 1px solid transparent;
      opacity: 0;

      > * {
        opacity: 0;
        transition: opacity 0.3s;
      }

      &--active {
        transition: transform 0.3s, opacity 0.3s, border-color 0.3s linear 0.3s;
        transform: translate3d(0, 0, 0);
        border-color: var(--text-c-4);
        opacity: 1;

        > * {
          opacity: 1;
          transition: opacity 0.3s linear 0.3s;
        }
      }

      &-item {
        margin: 0 24px;
      }
    }
  }
}
