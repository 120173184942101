:root {
  --gmaps-btn-w: 64px;
}

.gmaps {
  &-wrap {
    &--desktop {
      --gmaps-btn-w: 80px;
      min-height: 584px;
    }

    &--mobile {
      width: 100%;
      aspect-ratio: 0.75;
    }
  }

  &-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 8;
    background: rgba(#1A1A1A, 0.5);

    &-btn-wrap {
      min-width: var(--gmaps-btn-w);
    }

    &-info-wrap {
      border-radius: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
