.bg-none {
  background-color: transparent;
}

.bg-primary {
  background-color: var(--primary-c);
}

.bg-primary-dark {
  background-color: var(--primary-c-dark);
}

.bg-primary-light {
  background-color: var(--primary-c-light);
}

.bg-active {
  background-color: var(--active-c);
}

.bg-active-dark {
  background-color: var(--active-c-dark);
}

.bg-active-light {
  background-color: var(--active-c-light);
}

.bg-error {
  background-color: var(--c-error);
}

.bg-1 {
  background-color: var(--bg-c-1);
}

.bg-2 {
  background-color: var(--bg-c-2);
}

.bg-3 {
  background-color: var(--bg-c-3);
}

.bg-4 {
  background-color: var(--bg-c-4);
}

.bg-5 {
  background-color: var(--bg-c-5);
}

.bg-6 {
  background-color: var(--bg-c-6);
}

.bg-7 {
  background-color: var(--bg-c-7);
}

.bg-9 {
  background-color: var(--bg-c-9);
}

.bg-11 {
  background-color: var(--bg-c-11);
}

.bg-12 {
  background-color: var(--bg-c-12);
}
.bg-13 {
  background-color: var(--bg-c-13);
}

.c-primary {
  color: var(--primary-c);
}

a.c-primary, button.c-primary {
  font-weight: 700;

  &, *, .c-primary {
    color: var(--primary-c-light);
  }
}

a, button {
  .c-primary {
    color: var(--primary-c-light);
  }
}

.c-primary-dark {
  color: var(--primary-c-dark);
}

.c-primary-light {
  color: var(--primary-c-light);
}

.c-active {
  color: var(--active-c);
}

.c-active-dark {
  color: var(--active-c-dark);
}

.c-active-light {
  color: var(--active-c-light);
}

.c-gold {
  color: var(--c-gold);
}

.c-info {
  color: var(--c-info);
}

.c-error {
  color: var(--c-error);
}

.c-success {
  color: var(--c-success);
}
.c-price {
  color: var(--c-price)
}

.c-warning {
  color: var(--c-warning);
}

.c-text-white {
  color: #fff;
}

.c-text-1 {
  color: var(--text-c-1);
}

.c-text-2 {
  color: var(--text-c-2);
}

.c-text-3 {
  color: var(--text-c-3);
}

.c-text-4 {
  color: var(--text-c-4);
}

.c-text-5 {
  color: var(--text-c-5);
}

.c-bg-2 {
  color: var(--bg-c-2);
}

.border-c-text-1 {
  border-color: var(--text-c-1);
}

.border-success {
  border: 1px solid var(--c-success);
}

.border-info{
  border: 1px solid var(--c-info);
}

.border-error{
  border: 1px solid var(--c-error);
}

.border-price{
  border: 1px solid var(--c-price);
}

.border-c-success {
  border-color: var(--c-success);
}

.border-c-error {
  border-color: var(--c-error);
}

.border-c-info {
  border-color: var(--c-info);
}


