.timepicker {
  &-list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border-radius: var(--br-base);
    border: var(--input-bw) solid var(--bg-c-7);
    height: 180px;
    overflow: hidden;
    overflow-y: auto;
    margin-top: 10px;
    z-index: 100;
    background-color: var(--bg-c-3);
    display: none;

    &--open {
      display: block;
    }

    &-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: var(--input-h);
      padding: 0 var(--input-p-h);
      color: var(--input-c);
      font-size: var(--input-fs);
      font-weight: var(--input-fw);
      line-height: var(--input-lh);
      cursor: pointer;
      transition: all .3s;

      &:hover {
        color: var(--text-c-1);
        background-color: var(--bg-c-7);
      }
    }
  }
}

.bankroll-g-recaptcha-holder {
  overflow: hidden;
  width: 298px;
  height: 74px;

  iframe {
    margin: -1px 0 0 -2px;
  }
}
