.community {
  &-wrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: var(--padding-h) 0;
  }

  &-menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    &-item {
      $self: &;
      padding: 8px;
      background: var(--bg-c-3);
      border-radius: var(--br-base);
      margin-bottom: 15px;

      &:disabled,
      &[disabled] {
        background: var(--bg-c-3) !important;
      }

      &-header {
        position: absolute;
        left: 8px;
        bottom: 8px;
        right: 8px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        text-align: left;
        min-height: 80px;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.4) 100%);
        border-radius: 12px;
        padding: 8px 16px;
        z-index: 1;

        &:before {
          content: "";
          display: block;
          position: absolute;
          left: 8px;
          top: 8px;
          bottom: 8px;
          width: 2px;
          background: var(--primary-c);
        }
      }

      &-in {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        position: relative;
        background: var(--bg-c-5);
        border-radius: inherit;
        aspect-ratio: 2.05625;
        overflow: hidden;
        --icon-w: 48px;
        --icon-h: 48px;
        --icon-fs: 48px;
      }

      &--desktop {
        margin-bottom: 0;
        border-radius: 24px;
        padding: 15px;

        #{$self}-header {
          padding: 24px 24px 24px 48px;
          border-radius: 12px;
          min-height: 128px;

          &:before {
            top: 24px;
            bottom: 24px;
            left: 24px;
          }
        }

        #{$self}-in {
          border-radius: 12px;
        }
      }
    }
  }
}
