:root {
  --restaurant-logo-h: 32px;
  --restaurant-btns-mw: 240px;
}

@import url("https://fonts.googleapis.com/css2?family=Lora:wght@600&family=Work+Sans:wght@300&display=swap");

$font-lora: "Lora", serif;
$font-work: "Work Sans", sans-serif;

.font-lora {
  font-family: $font-lora;
  font-weight: 600;
}

.font-work {
  font-family: $font-work;
  font-weight: 300;
}

.restaurant {
  &-menu-desktop {
    &-wrap {
      column-count: 2;
      gap: 32px;
    }

    &-section {
      break-inside: avoid;
    }
  }

  &-slider {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: nowrap;
    transition: transform 0.3s;

    &-wrap {
      position: relative;
      width: 100%;
      height: 100%;
      flex-grow: 1;
      overflow: hidden;
    }

    &-arrow {
      position: absolute;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      z-index: 1;

      &--left {
        left: 0;
      }

      &--right {
        right: 0;
      }
    }

    &-item {
      min-width: 100%;
      max-width: 100%;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }

  &-wrap {
    background-image: url(/assets/restaurant-bg-mobile.jpg);
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;

    &--desktop {
      width: 100%;
      aspect-ratio: 1240/880;
      padding-top: 24px;
      padding-bottom: 24px;
      border: 1px solid #4f4f4f;
      border-radius: 8px;
      background-image: url(/assets/restaurant-bg-desktop.jpg);

      --restaurant-logo-h: 48px;
      --restaurant-btns-mw: 272px;

      .restaurant-btns {
        padding: 24px;
        background: rgba(11, 5, 5, 0.5);
        border-radius: 12px;
      }

      .restaurant-footer {
        max-width: 368px;
        margin: 24px auto 0 auto;
        bottom: 24px;
        padding: 24px;
        width: 100%;
        background: rgba(11, 5, 5, 0.5);
        border-radius: 12px;
      }
    }
  }

  &-header,
  &-bottom-block {
    width: 100%;
    align-self: center;
    max-width: var(--restaurant-btns-mw);
  }

  &-bottom-block-in {
    background: rgba(0, 0, 0, 0.5);
  }

  &-text-block {
    background: rgba(0, 0, 0, 0.8);
    border: 1px solid var(--text-c-3);
    border-radius: 8px;
    max-width: 480px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  &-logo {
    height: var(--restaurant-logo-h);
  }

  &-btns {
    width: 100%;
    max-width: var(--restaurant-btns-mw);
    margin-left: auto;
    margin-right: auto;
  }

  &-footer {
    padding: var(--padding-h);
    margin: auto calc(-1 * var(--padding-h)) 0;
    background-color: rgba(#000, 0.5);
  }
}
