:root {
  --input-h: 38px;
  --input-p-v: 9px;
  --input-p-h: 8px;
  --input-p: var(--input-p-v) var(--input-p-h);
  --input-bw: 2px;
  --input-br: 4px;
  --input-fw: 500;
  --input-fs: 14px;
  --input-lh: 20px;
  --input-c: var(--text-c-3);
  --input-icon-w: 18px;
  --input-icon-p: 32px;
  --input-textarea-h: 120px;
  --select-c: var(--text-c-3);
  --select-border-c: var(--bg-c-3);
  --input-p-r: 30px;
}

input::-webkit-calendar-picker-indicator {
  color: transparent !important;
  //opacity: 0!important;
  width: 20px;
  height: 20px;
}

input::-webkit-datetime-edit-year-field {
  color: inherit !important;
}

input::-webkit-datetime-edit-year-field:disabled {
  color: inherit !important;
}

input::-webkit-datetime-edit-year-field[disabled] {
  color: inherit !important;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"],
input[type="week"] {
  padding: 0;
}

@media (prefers-color-scheme: light) {
  .select {
    color: var(--select-c);
  }
}

.select {
  $self: &;
  display: block;
  width: 100%;
  height: var(--input-h);
  padding: calc(var(--input-p-v) - var(--input-bw)) var(--input-p-r)
    calc(var(--input-p-v) - var(--input-bw)) var(--input-p-h);
  color: var(--select-c);
  background-color: var(--bg-c-3);
  outline: none;
  border: var(--input-bw) solid var(--select-border-c);
  border-radius: var(--input-br);
  font-size: var(--input-fs);
  font-weight: var(--input-fw);
  line-height: var(--input-lh);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: color 0.3s, border-color 0.3s, background-color 0.3s;

  &-wrap {
    position: relative;

    &--selected {
      --select-c: var(--text-c-1);
      --select-border-c: var(--bg-c-7);
    }
  }

  &-icon {
    position: absolute;
    top: 0;
    right: 0;
    width: var(--input-p-r);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    color: var(--text-c-3);
    z-index: 1;
    --icon-w: 14px;
    --icon-h: 14px;
    --icon-fs: 14px;
  }

  &--sm {
    --input-fs: 12px;
    --input-h: 32px;
    --input-p-v: 6px;
    --input-p-h: 8px;
  }
}

.input,
.input-wrapper input {
  $self: &;
  display: block;
  width: 100%;
  height: var(--input-h);
  padding: var(--input-p);
  color: var(--input-c);
  background-color: var(--bg-c-3);
  outline: none;
  border: var(--input-bw) solid var(--bg-c-3);
  border-radius: var(--input-br);
  font-size: var(--input-fs);
  font-weight: var(--input-fw);
  line-height: var(--input-lh);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: color 0.3s, border-color 0.3s, background-color 0.3s;
  box-sizing: border-box;

  &::-webkit-input-placeholder {
    color: var(--input-c);
  }

  &::-moz-placeholder {
    color: var(--input-c);
  }

  &:-ms-input-placeholder {
    color: var(--input-c);
  }

  &:-moz-placeholder {
    color: var(--input-c);
  }

  &:focus,
  &:active,
  &--single {
    background-color: var(--bg-c-3);
    border-color: var(--bg-c-7);
    --input-c: var(--text-c-1);
  }

  &--outline {
    &,
    &#{$self}--single {
      background-color: transparent;
      border-color: var(--text-c-3);
      --input-c: var(--text-c-3);

      &:focus,
      &:active {
        border-color: var(--text-c-1);
        color: var(--text-c-1);
      }
    }
  }

  &--error,
  &.error {
    &,
    &:focus,
    &:active {
      --input-c: var(--text-c-3);
      border-color: var(--c-error);
    }
  }

  &-icon {
    --icon-w: var(--input-icon-w);
    --icon-h: 100%;
    --icon-fs: 16px;
    position: absolute;
    top: 0;
    z-index: 1;
    pointer-events: none;

    &--left {
      left: var(--input-p-v);

      ~ #{$self} {
        padding-left: var(--input-icon-p);
      }
    }

    &--right {
      right: var(--input-p-v);

      ~ #{$self} {
        padding-right: var(--input-icon-p);
      }
    }
  }

  &-msg {
    display: block;
    width: 100%;
    min-height: calc(4px + var(--lh-md));
    font-size: var(--fs-sm);
    line-height: var(--lh-md);
    font-weight: 500;
    padding-top: 4px;
  }

  &-label {
    padding-top: 0;
    padding-bottom: 6px;
    color: var(--text-c-3);
  }

  &-holder {
    position: relative;
  }

  &--sm {
    --input-fs: 12px;
    --input-h: 32px;
    --input-p-v: 6px;
    --input-p-h: 8px;
  }

  &--single {
    width: var(--input-h);
    text-align: center;
  }

  &-wrap {
    &--sm {
      flex-basis: 80px;
      flex-shrink: 0;
    }

    &--single {
      .input {
        &:not(:first-child) {
          margin-left: 8px;
        }
      }
    }
  }
}

.loginform--desktop {
  display: flex;
  width: 400px;
  height: auto;
  min-height: 510px;
  padding: 9px 9px 24px 9px;
  border: 1px solid var(--primary-c);
  background: var(--bg-c-2);
  box-shadow: 0 4px 18px rgba(#000, 0.25);
  border-radius: 8px;
  overflow: hidden;

  &--sm {
    min-height: 488px;
  }
}

div.timeselect {
  padding-top: var(--input-p) !important;
  padding-bottom: var(--input-p) !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  color: var(--input-c) !important;
  background-color: var(--bg-c-3) !important;
  outline: none !important;
  border: var(--input-bw) solid var(--bg-c-7) !important;
  border-radius: var(--input-br) !important;
  font-size: var(--input-fs) !important;
  font-weight: var(--input-fw) !important;
  line-height: var(--input-lh) !important;
  transform: translate3d(0, 10px, 0);
  width: 160px !important;

  a {
    font-size: inherit !important;
    line-height: inherit !important;
    color: inherit !important;
    padding: 0 var(--input-p) !important;

    &:first-child {
      margin-top: var(--input-p) !important;
    }

    &:last-child {
      margin-bottom: var(--input-p) !important;
    }

    &:hover {
      color: var(--bg-c-3) !important;
      background-color: var(--text-c-1) !important;
    }
  }
}

.login-page {
  position: relative;
  background-image: url(/assets/bg-texture.webp);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  isolation: isolate;

  &--desktop {
    background-image: url(/assets/bg-texture.webp);
  }

  &-inner {
    max-width: 705px;
    margin-left: auto;
    margin-right: auto;

    form {
      width: 100%;
      max-width: 400px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.radio {
  $self: &;

  &-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
  }

  &-inp {
    &:checked {
      ~ #{$self}-icon {
        &:after {
          opacity: 1;
        }
      }
    }
  }

  &-icon {
    position: relative;
    display: block;
    width: 40px;
    height: 40px;

    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      z-index: 1;
      border-radius: 50%;
      transition: opacity 0.3s;
    }

    &:before {
      width: 20px;
      height: 20px;
      border: 1px solid var(--text-c-2);
    }

    &:after {
      width: 10px;
      height: 10px;
      background-color: var(--text-c-2);
      opacity: 0;
    }
  }

  &-txt {
    display: block;
    font-size: var(--fs-lg);
    line-height: var(--lh-lg);
    font-weight: var(--fw-500);
    color: var(--text-c-1);
  }
}
