:root {
  --partners-logo-h: 40px;
}

.parnters {
  &-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    --partners-logo-h: 48px;
  }

  &-item {
    &-logo {
      width: 100%;
      height: var(--partners-logo-h);
    }
  }
}
