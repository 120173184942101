.block {
  display: block;
}

.inline {
  display: inline;
}

.grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 8px;

  &-1-1 {
    grid-template-columns: 1fr 1fr;
  }

  &-1-1-1 {
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  }

  &-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }
}

.flex {
  display: flex;

  &-col {
    flex-direction: column;

    &-reverse {
      flex-direction: column-reverse;
    }
  }

  &-row {
    flex-direction: row;

    &-reverse {
      flex-direction: row-reverse;
    }
  }

  &-wrap {
    flex-wrap: wrap;
  }
  &-nowrap{
    flex-wrap: nowrap;
  }
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-stretch {
  justify-content: stretch;
}

.justify-between {
  justify-content: space-between;
}

.align-start {
  align-items: flex-start;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.align-stretch {
  align-items: stretch;
}

.grow-0 {
  flex-grow: 0;
}

.grow-1 {
  flex-grow: 1;
}

.grow-2 {
  flex-grow: 2;
}

.shrink-0 {
  flex-shrink: 0;
}

.shrink-1 {
  flex-shrink: 1;
}

.shrink-2 {
  flex-shrink: 2;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.mt-0 {
  margin-top: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.mt-auto {
  margin-top: auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mb-auto {
  margin-bottom: auto;
}

.ml-0 {
  margin-left: 0;
}

.mr-0 {
  margin-right: 0;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.pt-0 {
  padding-top: 0;
}

.pb-0 {
  padding-bottom: 0;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.pl-0 {
  padding-left: 0;
}

.pr-0 {
  padding-right: 0;
}

.b-0 {
  border: 0;
}

@for $i from 1 through 20 {
  $step: 4px;

  .my-#{$i} {
    margin-top: $i * $step;
    margin-bottom: $i * $step;
  }

  .my-neg-#{$i} {
    margin-top: -$i * $step;
    margin-bottom: -$i * $step;
  }

  .mt-#{$i} {
    margin-top: $i * $step;
  }

  .mt-neg-#{$i} {
    margin-top: -$i * $step;
  }

  .mb-#{$i} {
    margin-bottom: $i * $step;
  }

  .mb-neg-#{$i} {
    margin-bottom: -$i * $step;
  }

  .mx-#{$i} {
    margin-left: $i * $step;
    margin-right: $i * $step;
  }

  .mx-neg-#{$i} {
    margin-left: -$i * $step;
    margin-right: -$i * $step;
  }

  .ml-#{$i} {
    margin-left: $i * $step;
  }

  .ml-neg-#{$i} {
    margin-left: -$i * $step;
  }

  .mr-#{$i} {
    margin-right: $i * $step;
  }

  .mr-neg-#{$i} {
    margin-right: -$i * $step;
  }

  .py-#{$i} {
    padding-top: $i * $step;
    padding-bottom: $i * $step;
  }

  .pt-#{$i} {
    padding-top: $i * $step;
  }

  .pb-#{$i} {
    padding-bottom: $i * $step;
  }

  .px-#{$i} {
    padding-left: $i * $step;
    padding-right: $i * $step;
  }

  .pl-#{$i} {
    padding-left: $i * $step;
  }

  .pr-#{$i} {
    padding-right: $i * $step;
  }

  .top-#{$i} {
    top: $i * $step;
  }

  .right-#{$i} {
    right: $i * $step;
  }

  .bottom-#{$i} {
    bottom: $i * $step;
  }

  .left-#{$i} {
    left: $i * $step;
  }
}

.svg {
  display: block;
  height: auto;
  width: 100%;

  &-fill {
    fill: currentColor;
  }
}

.img {
  display: block;
  max-width: 100%;
  height: auto;

  &--inPopup {
    max-width: 600px;
  }

  &--square {
    display: block;
    max-width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
    object-position: center center;
  }

  &--fill {
    flex: 1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }

  &--contain {
    flex: 1;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center center;
  }

  &--full {
    width: 100%;
    height: auto;
  }
}

.img-league{
  width: 16px;
  height: 16px;
  object-fit: contain;vertical-align: middle;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.z-1 {
  z-index: 1;
}

.border-0 {
  border: none;
}

.w-33 {
  width: calc(100% / 3);
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.w-auto {
  width: auto;
}

.h-auto {
  height: auto;
}

.br-desktop {
  border-radius: 32px;
}

.pointer-all {
  pointer-events: all;
}

.pointer-none {
  pointer-events: none;
}

.pointer {
  cursor: pointer;
}

.border-bottom {
  border-bottom: 1px solid;
}

.border-full {
  border: 1px solid var(--primary-c-light);
 &--desktop{
    border-width: 2px;
  }
  &--pick-win {
    border: 8px solid var(--bg-c-3);
  }
}

.hidden {
  display: none;
}

.contain {
  background-size: contain;
}
