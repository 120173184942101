.prize {
  &-wrapper {
    max-width: 256px;

    &--desktop {
      max-width: 364px;
    }

    &--screen {
      max-width: 420px;
    }

    &--pick-win {
      max-width: 233px;
    }


  }

  &-svg {
    &-holder {
      max-width: 180px;

      &--desktop {
        max-width: 256px;
      }
    }
  }

  &-media {
    aspect-ratio: 328/224;
    object-fit: contain;
    &-xs {
      aspect-ratio: 240/164;
      object-fit: contain;
    }
    &-pick-win {
      aspect-ratio: 364/216;
      object-fit: contain;
    }
    &--desktop {
      aspect-ratio: 233/160;
      object-fit: contain;
    }
    &--mobile {
      aspect-ratio: 345/248;
      object-fit: contain;
    }
  }
  &-slider {
    position: absolute;

    &--left {
      transform: translate(50%, -115%);
      left: 0;
      top: 50%;
    }
    &--right {
      transform: translate(-50%, -115%);
      right: 0;
      top: 50%;
    }
  }
  .slide {
    transform: translateX(-100%);
  }
  &--video-wrapper {
    aspect-ratio: 424/272;
    max-width: 100%;
    width: 100%;
    height: 100%;
  }
  &--video {
    object-fit: contain;
    max-width: 100%;
    width: 100%;
    height: 100%;
  }

}

.play-btn{
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
 transform: translate(-50%, -50%);
}

.slide {
  transform: translateX(-100%);
}

.transition {
  transition: transform 0.5s ease-in-out;
}

.step {
  width: 20px;
  height: 20px;
  border: 3px solid var(--primary-c);
  background-color: transparent;
  opacity: 0.5;
  border-radius: 50%;
}

.step.fill {
  background-color: var(--primary-c);
}

.move-screen {
  position: absolute;
  bottom: 0;
  transform: translateX(-50%);
  left: 50%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.move {
  width: 100%;
  align-items: center;
  justify-content: center;
}
