:root {
  --area-select-media-w: 108px;
  --area-select-media-mh: 78px;
}

.area-select {
  $self: &;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  padding: 8px;
  border-radius: var(--br-base);
  background-color: var(--bg-c-3);
  border: 1px solid transparent;

  &--desktop {
    --area-select-media-w: 124px;
    --area-select-media-mh: 78px;
  }

  &--active {
    border-color: var(--primary-c);
  }

  &.disabled {
    pointer-events: none;

    #{$self}-media {
      img {
        opacity: .5;
      }
    }

    #{$self}-title {
      color: var(--text-c-3);
    }

    #{$self}-seats, #{$self}-btn {
      color: var(--text-c-4);
    }
  }

  &-media {
    position: relative;
    display: flex;
    flex: 0 0 var(--area-select-media-w);
    width: var(--area-select-media-w);
    min-height: var(--area-select-media-mh);
    border-radius: 4px;
    overflow: hidden;
  }

  &-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding-left: 8px;
    text-align: left;
  }

  &-title {
    display: block;
    font-size: var(--fs-md);
    line-height: var(--lh-lg);
    font-weight: var(--fw-700);
    color: var(--text-c-1);
    margin-bottom: 4px;
    text-transform: uppercase;
  }

  &-seats {
    display: block;
    font-size: var(--fs-sm);
    line-height: var(--lh-md);
    font-weight: var(--fw-500);
    color: var(--text-c-3);
    margin-bottom: 6px;
  }

  &-footer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
    margin-top: auto;
  }

  &-soldout {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: var(--fs-md);
    font-weight: var(--fw-700);
    line-height: var(--lh-lg);
    color: var(--primary-c);
  }

  &-btn {
    color: var(--text-c-2);
  }
}
