.home {
  &-event {
    $self: &;

    &-list {
      max-width: 265px;
      margin-left: auto;
      margin-right: auto;
    }

    &-header {
      &-arrow {
        transition: all 0.3s;
      }
    }

    &-wrap {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      background: var(--bg-c-2);
      border: 1px solid var(--text-c-4);
      border-radius: 8px;
      padding: 7px 16px;
    }

    &-body {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s;
    }

    &-check {
      &:checked {
        ~ #{$self}-header {
          #{$self}-header-arrow {
            transform: rotate(180deg);
          }
        }

        ~ #{$self}-body {
          max-height: 162px;
        }
      }
    }

    &-slider {
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      flex-wrap: nowrap;
      max-width: 100%;
      transform: translate3d(0, 0, 0);
      will-change: transform;
      transition: transform 0.3s;

      &-wrap {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        overflow: hidden;
        flex-wrap: wrap;
        margin-left: -12px;
        margin-right: -12px;
      }

      &-item {
        width: 33.3333%;
        flex: 0 0 33.3333%;
        padding-left: 12px;
        padding-right: 12px;

        .home-event-wrap {
          padding: 24px;
          height: 100%;
        }

        .events-list-item {
          height: 100%;
        }

        .events-list-item-media {
          flex: 0 0 120px;
          max-width: 120px;
          height: 88px;
        }

        .events-list-item-date {
          flex: 0 0 66px;
          max-width: 66px;
          width: 66px;
          height: 88px;
          background-color: var(--bg-c-3);
          padding: 8px;
        }

        .events-list-item-day {
          height: 32px;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
        }

        .events-list-item-num {
          font-size: var(--fs-xxl);
        }

        .events-list-item-content > p {
          height: calc(2 * var(--lh-md));
        }

        .events-list-item-content {
          margin-top: 16px;
          margin-bottom: 16px;
        }

        .events-list-item-footer {
          margin-top: auto;
        }
      }

      &-arrow {
        --icon-w: 48px;
        --icon-h: 48px;
        --icon-fs: 48px;

        &--mobile {
          --icon-w: 24px;
          --icon-h: 24px;
          --icon-fs: 24px;
        }
      }

      &-dot {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: var(--text-c-3);
        transition: background-color 0.3s;
        cursor: pointer;

        &--active {
          background-color: var(--primary-c);
        }
      }
    }
  }

  &-coming-soon-block {
    border: 1px solid var(--text-c-3);
    border-radius: 16px;
    padding: 80px;
    margin-top: 80px;
    &-mobile {
      padding: 41.5px 10px;
      margin-top: 24px;
    }
  }
}

.homepage-item {
  $self: &;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  border-radius: 16px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  &-arrow {
    border-radius: 4px;

    &--desktop {
      border-radius: 16px;
      --icon-fs: 40px;
      --icon-w: 48px;
      --icon-h: 48px;
    }
  }

  &-checkbox {
    &:checked {
      ~ #{$self}-holder-wrap {
        > #{$self}-label {
          pointer-events: none;
        }

        #{$self}-holder {
          transform: rotateY(0deg);
        }
      }
    }
  }

  &-label {
    position: absolute;
    inset: 0;
    border-radius: inherit;
    overflow: hidden;
    display: block;
    z-index: 7;
    opacity: 0;
    cursor: pointer;
  }

  &-holder {
    --homepage-item-p: 16px;
    position: relative;
    display: flex;
    width: 100%;
    aspect-ratio: 1.7388888889;
    flex-direction: column;
    align-items: stretch;
    border-radius: 16px;
    background-color: var(--bg-c-1);
    border: 1px solid var(--text-c-4);
    padding: var(--homepage-item-p);
    cursor: pointer;
    pointer-events: all;

    &--desktop {
      aspect-ratio: 2.04166666667;
    }

    &--disable {
      opacity: 0.2;
      pointer-events: none;
    }

    &-wrap {
      position: relative;
      border-radius: 16px;
      z-index: 1;
      transform: translate3d(0, 0, 0);
      background-color: var(--bg-c-1);
      pointer-events: none;

      .br-tooltip-wrap {
        position: absolute;
        top: 24px;
        right: 24px;
        left: 24px;
        z-index: 10;
      }

      &--mobile {
        #{$self} {
          border-radius: 8px;
        }

        .br-tooltip-wrap {
          right: 16px;
          left: 16px;
          top: 16px;
        }
      }
    }
  }

  &-title,
  &-content {
    background: rgba(#1a1a1a, 0.5);
    border-radius: 12px;
  }

  &-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    a,
    button {
      position: relative;
      z-index: 8;
    }
  }

  &--frontside {
    height: 100%;
    background-color: var(--bg-c-1);
    border: 1px solid var(--text-c-4);
    padding: var(--homepage-item-p);
    border-radius: inherit;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;

    #{$self}-title {
      background: transparent;
    }

    #{$self} {
      isolation: isolate;

      &:before {
        content: "";
        position: absolute;
        inset: 0;
        z-index: -1;
        border-radius: inherit;
        background: rgba(0, 0, 0, 0.55);
      }
    }
  }

  &--backside {
    height: 100%;
    background: var(--bg-c-2);
    border-radius: 16px;
    position: relative;
    z-index: 2;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
  }
}
