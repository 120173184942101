.menu-wrap {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#000, 0.5);
  opacity: 0;
  transition: opacity 0.3s linear 0.3s;
  z-index: 9999;
  pointer-events: none;

  &.menu--open {
    opacity: 1;
  }
}

.menu {
  $self: &;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 280px;
  height: 100%;
  padding: 0 var(--padding-h);
  background-color: var(--bg-c-2);
  border-radius: 0 8px 8px 0;
  overflow-y: auto;
  overflow-x: hidden;
  transform: translate3d(-100%, 0, 0);
  transition: transform 0.3s;
  z-index: 1;

  &--open {
    pointer-events: all;
    transition: opacity 0.3s;

    #{$self} {
      transform: translate3d(0, 0, 0);
      transition: transform 0.3s linear 0.3s;
    }
  }

  &-header {
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: var(--bg-c-2);
    margin-left: calc(-1 * var(--padding-h));
    margin-right: calc(-1 * var(--padding-h));
    padding: 0 var(--padding-h);
    height: 42px;
    min-height: 42px;
    margin-bottom: 7px;
  }

  &-close {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 11;
  }

  &-profile {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 8px;
    margin-bottom: 7px;

    &-pic {
      flex: 0 0 36px;
      min-width: 36px;
      overflow: hidden;
      margin-right: 8px;
      border-radius: 4px;
    }

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
    }
  }

  &-title {
    display: block;
    font-size: var(--fs-lg);
    font-weight: var(--fw-700);
    line-height: var(--lh-lg);
    color: var(--text-c-1);
    margin-bottom: 8px;

    &:not(:first-child) {
      margin-top: 8px;
    }

    &-selectable {
      text-decoration: underline;
      text-underline-offset: 5px;
    }
  }

  &-item {
    display: block;
    font-size: var(--fs-md);
    font-weight: var(--fw-500);
    line-height: var(--lh-xl);
    color: var(--primary-c-light);
    margin-bottom: 8px;
    margin-left: 10px;

    &:last-child {
      margin-bottom: var(--padding-h);
    }
  }

  &-signout {
    position: sticky;
    bottom: 0;
    left: 0;
    margin: auto calc(-1 * var(--padding-h)) 0 calc(-1 * var(--padding-h));
    padding: 8px var(--padding-h);
    background-color: var(--bg-c-2);
    z-index: 10;
  }
}
