@keyframes loader-1 {
  0%,
  13.6% {
    transform: translate3d(120.5px, 0, 0) rotateZ(0deg) scale(1);
  }

  17% {
    transform: translate3d(120.5px, 0, 0) rotateZ(45deg) scale(1.2);
  }

  20.4% {
    transform: translate3d(120.5px, 0, 0) rotateZ(90deg) scale(1.4);
  }

  23.8% {
    transform: translate3d(120.5px, 0, 0) rotateZ(180deg) scale(1.4);
  }

  27.2% {
    transform: translate3d(120.5px, 0, 0) rotateZ(225deg) scale(1.2);
  }

  30.6%,
  58.3%,
  100% {
    transform: translate3d(120.5px, 0, 0) rotateZ(360deg) scale(1);
  }

  72.58%,
  86.18% {
    transform: translate3d(0, 0, 0) rotateZ(360deg) scale(1);
  }
}

@keyframes loader-2 {
  0%,
  56.26%,
  100% {
    opacity: 0;
    transform: translate3d(101px, 0, 0);
  }

  58.3% {
    opacity: 1;
    transform: translate3d(101px, 0, 0);
  }

  72.58%,
  86.18% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes loader-3 {
  0%,
  58.3%,
  100% {
    opacity: 0;
    transform: translate3d(83.5px, 0, 0);
  }

  60.34% {
    opacity: 1;
    transform: translate3d(83.5px, 0, 0);
  }

  72.58%,
  86.18% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes loader-4 {
  0%,
  60.34%,
  100% {
    opacity: 0;
    transform: translate3d(63.5px, 0, 0);
  }

  62.38% {
    opacity: 1;
    transform: translate3d(63.5px, 0, 0);
  }

  72.58%,
  86.18% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes loader-5 {
  0%,
  62.38%,
  100% {
    opacity: 0;
    transform: translate3d(43.5px, 0, 0);
  }

  64.42% {
    opacity: 1;
    transform: translate3d(43.5px, 0, 0);
  }

  72.58%,
  86.18% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  98% {
    opacity: 0;
  }
}

@keyframes loader-6 {
  0%,
  64.42%,
  100% {
    opacity: 0;
    transform: translate3d(25.5px, 0, 0);
  }

  66.46% {
    opacity: 1;
    transform: translate3d(25.5px, 0, 0);
  }

  72.58%,
  86.18% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  96% {
    opacity: 0;
  }
}

@keyframes loader-7 {
  0%,
  66.46%,
  100% {
    opacity: 0;
    transform: translate3d(14.5px, 0, 0);
  }

  68.5% {
    opacity: 1;
    transform: translate3d(14.5px, 0, 0);
  }

  72.58%,
  86.18% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  94% {
    opacity: 0;
  }
}

@keyframes loader-8 {
  0%,
  68.5%,
  100% {
    opacity: 0;
    transform: translate3d(0, 0, 0);
  }

  70.54% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  72.58%,
  86.18% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  92% {
    opacity: 0;
  }
}

// 1 start = 18
// 2 start = 54
// 3 start = 91
// 4 start = 130
// 5 start = 169
// 6 start = 205
// 7 start = 241
// 8 start = 267

@keyframes loader-static-1 {
  0% {
    transform: translate3d(120.5px, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes loader-static-2 {
  0% {
    opacity: 0;
    transform: translate3d(101px, 0, 0);
  }

  14.285% {
    opacity: 1;
    transform: translate3d(101px, 0, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes loader-static-3 {
  0%,
  14.285% {
    opacity: 0;
    transform: translate3d(83.5px, 0, 0);
  }

  28.57% {
    opacity: 1;
    transform: translate3d(83.5px, 0, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes loader-static-4 {
  0%,
  28.57% {
    opacity: 0;
    transform: translate3d(63.5px, 0, 0);
  }

  42.855% {
    opacity: 1;
    transform: translate3d(63.5px, 0, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes loader-static-5 {
  0%,
  42.855% {
    opacity: 0;
    transform: translate3d(43.5px, 0, 0);
  }

  57.14% {
    opacity: 1;
    transform: translate3d(43.5px, 0, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes loader-static-6 {
  0%,
  57.14% {
    opacity: 0;
    transform: translate3d(25.5px, 0, 0);
  }

  71.425% {
    opacity: 1;
    transform: translate3d(25.5px, 0, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes loader-static-7 {
  0%,
  71.425% {
    opacity: 0;
    transform: translate3d(14.5px, 0, 0);
  }

  85.71% {
    opacity: 1;
    transform: translate3d(14.5px, 0, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes loader-static-8 {
  0%,
  85.71% {
    opacity: 0;
    transform: translate3d(0, 0, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

/*.loader {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  &--fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  }

  &-svg {
    display: block;

    > * {
      transform-box: fill-box;
      transform-origin: center center;
    }

    &-1 {
      transform: translate3d(120.5px, 0, 0);
      animation: loader-1 linear 2940ms infinite;
    }

    &-2 {
      opacity: 0;
      transform: translate3d(101px, 0, 0);
      animation: loader-2 linear 2940ms infinite;
    }

    &-3 {
      opacity: 0;
      transform: translate3d(83.5px, 0, 0);
      animation: loader-3 linear 2940ms infinite;
    }

    &-4 {
      opacity: 0;
      transform: translate3d(63.5px, 0, 0);
      animation: loader-4 linear 2940ms infinite;
    }

    &-5 {
      opacity: 0;
      transform: translate3d(43.5px, 0, 0);
      animation: loader-5 linear 2940ms infinite;
    }

    &-6 {
      opacity: 0;
      transform: translate3d(25.5px, 0, 0);
      animation: loader-6 linear 2940ms infinite;
    }

    &-7 {
      opacity: 0;
      transform: translate3d(14.5px, 0, 0);
      animation: loader-7 linear 2940ms infinite;
    }

    &-8 {
      opacity: 0;
      transform: translate3d(0, 0, 0);
      animation: loader-8 linear 2940ms infinite;
    }

    &-static {
      &-1 {
        animation: loader-static-1 linear 420ms forwards;
        animation-delay: 4s;
      }

      &-2 {
        animation: loader-static-2 linear 420ms forwards;
        animation-delay: 4s;
      }

      &-3 {
        animation: loader-static-3 linear 420ms forwards;
        animation-delay: 4s;
      }

      &-4 {
        animation: loader-static-4 linear 420ms forwards;
        animation-delay: 4s;
      }

      &-5 {
        animation: loader-static-5 linear 420ms forwards;
        animation-delay: 4s;
      }

      &-6 {
        animation: loader-static-6 linear 420ms forwards;
        animation-delay: 4s;
      }

      &-7 {
        animation: loader-static-7 linear 420ms forwards;
        animation-delay: 4s;
      }

      &-8 {
        animation: loader-static-8 linear 420ms forwards;
        animation-delay: 4s;
      }
    }
  }
}*/

.loader {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  &--fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  }
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

.load {
  width: 100px;
  height: 100px;
  margin: 110px auto 0;
  border: solid 10px white;
  border-radius: 50%;
  border-right-color: transparent;
  border-bottom-color: transparent;
  -webkit-transition: all 0.5s ease-in;
  -webkit-animation-name: rotate;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  transition: all 0.5s ease-in;
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
