:root {
  --footer-h: 46px;
}

.footer {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: 8;
  height: var(--footer-h);
  min-height: var(--footer-h);
  padding-left: var(--padding-h);
  padding-right: var(--padding-h);
  //margin-top: var(--padding-h);
  background-color: var(--bg-c-3);

  &--desktop {
    width: 100%;
    position: static;
  }

  &-block {
    width: 250px;
  }

  &-menu {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  &-copy {
    padding-top: 2px;
    text-align: center;
    font-weight: var(--fw-500);
    font-size: var(--fs-xs);
    line-height: var(--lh-md);
    color: var(--text-c-3);
  }
}
