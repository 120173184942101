.area-holder {
  flex: 1;
  overflow: hidden;
}

.area-wrap {
  flex: 1;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  overflow: hidden;
}

.area-container {
  --area-opacity: 0;
  position: relative;
  width: 100%;
  overflow: hidden;
  touch-action: pinch-zoom pan-x pan-y !important;
  -webkit-overflow-scrolling: touch;
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;

  &:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }

  &.has-selected {
    .area-table-details, .area-overlay {
      opacity: 1;
    }
    .area-overlay {
      pointer-events: all;
    }
  }
}

.area {
  position: relative;
  touch-action: pan-y !important;
  -webkit-overflow-scrolling: touch;
  will-change: transform, width, height;
  transform-origin: center center;

  &-initial {
    transition: transform .8s;
  }

  &-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: rgba(#000, .8);
    opacity: 0;
    pointer-events: none;
    will-change: opacity;
    transition: all .3s;
  }
}

.area-table {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  cursor: pointer;

  @media (any-hover: hover) {
    &:hover {
      filter: drop-shadow(0 0 2px var(--primary-c));
    }
  }

  img {
    pointer-events: none;
  }

  &--number {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    color: #C9C9C9;
  }

  &.selected {
    z-index: 11;
  }

  &.disabled {
    cursor: not-allowed;
    filter: grayscale(1);
  }
}

.area-table-details {
  position: absolute;
  left: 0;
  top: 0;
  width: 190px;
  padding: var(--padding-h);
  border: 2px solid var(--primary-c);
  border-radius: var(--br-base);
  background-color: var(--bg-c-3);
  opacity: 0;
  will-change: opacity;
  transition: all .3s;
  z-index: 10;
  pointer-events: none;

  &-row {
    display: flex;
    justify-content: space-between;
  }
}
