.count-selector {
  &, &-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-wrap {
    padding: var(--padding-h);
    background-color: var(--bg-c-3);
    border-radius: var(--br-base);

    &--transparent {
      padding: 0;
      background-color: transparent;
    }
  }

  &-title {
    font-size: var(--fs-sm);
    font-weight: var(--fw-500);
    line-height: var(--lh-md);
    color: var(--text-c-1);
  }

  &-value {
    display: block;
    min-width: 24px;
    font-size: var(--fs-md);
    font-weight: var(--fw-500);
    line-height: var(--lh-md);
    text-align: center;
  }
}
