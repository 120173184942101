:root {
  --icon-w: 24px;
  --icon-h: 24px;
  --icon-fs: 24px;
}

.bicon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--icon-w);
  height: var(--icon-h);
  font-size: var(--icon-fs);

  &--sm {
    --icon-w: 16px;
    --icon-h: 16px;
    --icon-fs: 16px;
  }

  &--md {
    --icon-w: 32px;
    --icon-h: 32px;
    --icon-fs: 32px;
  }

  &--lg {
    --icon-w: 40px;
    --icon-h: 40px;
    --icon-fs: 40px;
  }

  &--xl {
    --icon-w: 64px;
    --icon-h: 64px;
    --icon-fs: 64px;
  }

  &--xxl {
    --icon-w: 80px;
    --icon-h: 80px;
    --icon-fs: 80px;
  }
}

.round-icon {
  position: relative;
  width: 96px;
  aspect-ratio: 1;
  color: var(--round-icon-c);
  isolation: isolate;
  --icon-w: 32px;
  --icon-h: 32px;
  --icon-fs: 32px;

  &--success {
    --round-icon-c: #3AAC30;
    --round-icon-border-c: rgba(156, 200, 82, .2);
  }

  &--error {
    --round-icon-c: var(--c-error);
    --round-icon-border-c: #B10A0A;
  }

  &--info {
    --round-icon-c: #4C95EA;
    --round-icon-border-c: rgba(149, 192, 242, 0.2);
  }

  &, &:before, &:after, &-in {
    content: "";
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    aspect-ratio: 1;
  }

  &:before, &:after {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  &:before {
    width: 96px;
    background-color: var(--round-icon-c);
    opacity: .3;
    border: 2px solid var(--round-icon-border-c);
    z-index: -2;
  }

  &:after {
    width: 72px;
    background-color: var(--round-icon-c);
    opacity: .3;
    border: 2px solid var(--round-icon-border-c);
    z-index: -1;
  }

  &-in {
    width: 40px;
    background-color: var(--text-c-1);
    box-shadow: 0 4px 4px rgba(#000, .25);
  }
}
