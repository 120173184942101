.checkout-total {
  &-wrap {
    --checkout-br: 16px;
    --checkout-bg: var(--bg-c-3);
    position: relative;
    padding: 0 0 var(--padding-h) 0;

    &-in {
      position: relative;
      z-index: 2;
      padding: var(--padding-h);
      min-height: 99px;
      padding-bottom: 0;
      border-radius: var(--checkout-br) var(--checkout-br) 0 0;
      background-color: var(--checkout-bg);
    }
  }

  &-row {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;

    &:first-child {
      margin-top: 0;
    }
  }

  &-footer {
    border-top: 2px dashed var(--text-c-3);
    padding-top: 8px;
  }

  &-cutout {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    width: 100%;
    height: 24px;
    pointer-events: none;
    z-index: 1;
    overflow: hidden;

    &:before,
    &:after {
      content: "";
      display: block;
      width: 24px;
      height: 24px;
      flex: 0 0 24px;
      background-color: var(--checkout-bg);
    }

    &:before {
      border-radius: 0 0 0 var(--checkout-br);
    }

    &:after {
      border-radius: 0 0 var(--checkout-br) 0;
    }

    &-bg {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      overflow: hidden;

      &-holder {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        max-width: calc(100% - 48px);

        &:before {
          content: "";
          display: block;
          margin-left: -1px;
          margin-right: -1px;
          flex-grow: 1;
          background: var(--checkout-bg);
        }
      }
    }

    &-svg {
      display: block;
      min-width: 995px;
      min-height: 2px;
      fill: var(--checkout-bg);
      margin-left: -3px;
    }
  }
}

.general-admission {
  &-wrap {
    --ga-input-w: 160px;
    border: 1px solid var(--primary-c-light);
    border-radius: 8px;

    &--mobile {
      --ga-input-w: 100px;
    }
  }

  &-input {
    min-width: var(--ga-input-w);
  }

  &-btn {
    min-width: 110px;
  }
}
