:root {
  --sports-scroll-h: 74px;
}

.sports-scroll {
  $self: &;
  padding: 3px 47px;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  height: var(--sports-scroll-h);
  min-height: var(--sports-scroll-h);
  background-color: var(--bg-c-2);
  overflow: hidden;

  &-wrap {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    height: 100%;
    overflow-x: scroll;
    pointer-events: none;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &-in {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    overflow: hidden;

    &--left {
      ~ #{$self}-arrow-holder-left {
        opacity: 0;
        #{$self}-arrow {
          pointer-events: none;
        }
      }
    }

    &--right {
      ~ #{$self}-arrow-holder-right {
        opacity: 0;
        #{$self}-arrow {
          pointer-events: none;
        }
      }
    }
  }

  &-item {
    scroll-snap-align: start;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 72px;
    height: 100%;
    min-width: 72px;
    margin-left: 3px;
    border-radius: 4px;
    color: var(--text-c-3);
    background-color: var(--bg-c-3);
    pointer-events: all;
    --icon-w: 28px;
    --icon-h: 28px;
    --icon-fs: 28px;

    &--active {
      background-color: var(--sports-scroll-item-c);
      color: #fff;
      position: sticky;
      top: 0;
      left: 0;
      right: 0;
      z-index: 7;
    }

    &-title {
      display: block;
      font-size: var(--fs-xs);
      line-height: 1.2;
      padding-top: 4px;
    }

    &:first-child {
      margin-left: 0;
    }
  }

  &-arrow {
    width: 44px;
    height: 100%;
    border-radius: 4px;
    background-color: var(--bg-c-4);
    pointer-events: all;
    --icon-w: 28px;
    --icon-h: 28px;
    --icon-fs: 28px;

    &-holder {
      display: flex;
      position: absolute;
      top: 0;
      z-index: 5;
      width: 47px;
      height: var(--sports-scroll-h);
      background-color: var(--bg-c-2);
      padding: 3px 0;
      pointer-events: none;
      transition: opacity 0.3s;

      &-left {
        left: 0;
        padding-right: 3px;
      }

      &-right {
        right: 0;
        padding-left: 3px;
      }
    }
  }
}
