@keyframes filter-hide-anim {
  0%, 100% {
    opacity: 1;
  }

  33.33%, 80% {
    opacity: 0;
  }
}

@keyframes filter-show-anim {
  0%, 66.66% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes events-hide-anim {
  0% {
    opacity: 1;
  }

  0%, 33.33% {
    max-height: 100000px;
    overflow: unset;
  }

  33.33%, 100% {
    opacity: 0;
  }

  34%, 100% {
    max-height: 0;
    overflow: hidden;
  }
}

@keyframes events-show-anim {
  0%, 33.33% {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
  }

  34% {
    overflow: unset;
    max-height: 100000px;
  }

  66.66% {
    opacity: 0;
  }

  67%, 100% {
    opacity: 1;
    max-height: 100000px;
  }

  100% {
    overflow: unset;
  }
}

@keyframes events-filter-anim {
  0%, 100% {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: translate3d(0, 0, 0);
    z-index: 99;
    opacity: 0;
  }
}

@keyframes events-filter-open-anim {
  0% {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: translate3d(0, 0, 0);
    opacity: 0;
    z-index: 99;
  }

  100% {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: translate3d(0, 0, 0);
    opacity: 1;
    z-index: 99;
  }
}

@keyframes events-filter-sideopen-anim {
  0% {
    transform: translate3d(-100%, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

.filter-wrapper--desktop {
  opacity: 0;
  position: relative;
  z-index: 99;
}

.filter-popup-btns {
  display: none;
}

.toggle {
  $self: &;

  &-checkbox {
    &:checked {
      ~ #{$self}-graphic,
      ~ * #{$self}-graphic {
        --toggle-graphic-pos-x: 22px;
        --toggle-graphic-bg: var(--primary-c-light);
        --toggle-graphic-symbol-c: var(--text-c-1);
      }

      ~ #{$self}-hide {
      }

      ~ #{$self}-visible,
      ~ * #{$self}-visible {
        display: none;
      }
    }

    &:not(:checked) {
      ~ #{$self}-visible {
      }

      ~ #{$self}-hide,
      ~ * #{$self}-hide {
        display: none;
      }
    }

    &--desktop {
      &:checked {
        ~ .container--events {
          grid-template-columns: 0 1fr;

          .filter-wrapper--desktop {
            animation: filter-hide-anim linear .6s forwards;
          }

          .container--events_softhide {
            opacity: 1;
            transition: opacity .2s linear .4s;
            transform: translate3d(0, 0, 0);
          }

          .container--events_show {
            animation: events-hide-anim linear .6s forwards;
            max-height: 100000px;
            overflow: hidden;
            transform: translate3d(0, 0, 0);
          }

          .container--events_hide {
            animation: events-show-anim linear .6s forwards;
            max-height: 100000px;
            overflow: hidden;
            transform: translate3d(0, 0, 0);
          }

          .filter-popup-wrapper {
            animation: events-filter-anim linear .6s forwards;
            animation-delay: .6s;
            isolation: isolate;
            z-index: 99;

            .filter-popup-wrapper-backdrop {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              background: rgba(26, 26, 26, 0.9);
              opacity: 0.8;
              z-index: -1;
            }

            &.filter-popup-wrapper--active {
              animation: events-filter-open-anim linear .3s forwards;

              .filter-popup-inner {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: stretch;
                overflow-x: hidden;
                overflow-y: scroll;
                padding: 0 12px 0 16px;

                &::-webkit-scrollbar {
                  width: 4px;
                  height: 4px;
                  background-color: var(--bg-c-2); /* or add it to the track */
                }

                /* Add a thumb */
                &::-webkit-scrollbar-thumb {
                  background: var(--bg-c-5);
                }

                app-filter-by-date, > .btn:first-child {
                  display: none;
                }
              }

              .filter-popup-btns {
                display: block;
              }

              .filter-popup-in {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: stretch;
                width: 320px;
                height: 100%;
                overflow: hidden;
                background: var(--bg-c-2);
                border-radius: 0 8px 8px 0;
                isolation: isolate;
                animation: events-filter-sideopen-anim linear .3s forwards;

                &:before {
                  flex: 0;
                  content: attr(title);;
                  display: block;
                  padding: 0 16px;
                  height: var(--header-h);
                  line-height: var(--header-h);
                  font-size: var(--fs-xl);
                  font-weight: var(--fw-800);
                  background: #1A1A1A;
                  color: var(--text-c-1);
                }
              }
            }

            &:not(.filter-popup-wrapper--active) {
              pointer-events: none;
            }
          }
        }
      }

      &:not(:checked) {
        ~ .container--events {
          .filter-wrapper--desktop {
            animation: filter-show-anim linear .6s forwards;
          }

          .container--events_softhide {
            opacity: 0;
            transition: opacity .2s;
            pointer-events: none;
            transform: translate3d(0, 0, 0);
          }

          .container--events_show {
            animation: events-show-anim linear .6s forwards;
            max-height: 100000px;
            overflow: hidden;
            transform: translate3d(0, 0, 0);
          }

          .container--events_hide {
            animation: events-hide-anim linear .6s forwards;
            max-height: 100000px;
            overflow: hidden;
            transform: translate3d(0, 0, 0);
          }
        }
      }
    }
  }

  &-label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &-graphic {
    --toggle-graphic-pos-x: 3px;
    --toggle-graphic-bg: var(--text-c-4);
    --toggle-graphic-symbol-c: rgba(var(--text-c-4-rgb), 0.5);

    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 40px;
    height: 20px;
    cursor: pointer;
    background-color: var(--toggle-graphic-bg);
    border-radius: 10px;
    transition: background-color 0.3s;
    will-change: background-color;

    &:before {
      content: "";
      display: block;
      width: 16px;
      aspect-ratio: 1;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate3d(var(--toggle-graphic-pos-x), -50%, 0);
      background-color: var(--text-c-1);
      border-radius: 50%;
      z-index: 1;
      transition: transform 0.3s;
      will-change: transform;
    }
  }

  &-symbol {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;

    &:before {
      content: "";
      display: block;
      height: 8px;
      border-radius: 50%;
      color: var(--toggle-graphic-symbol-c);
      transition: color 0.3s;
    }

    &:first-child {
      &:before {
        width: 1px;
        background-color: currentColor;
      }
    }

    &:last-child {
      &:before {
        width: 8px;
        border: 1px solid currentColor;
      }
    }
  }
}
