@use "reset";
@use "components";
@use "user";
@use "account";
@use "welcome";
@use "welcome-anim";
@use "season";
@use "home";

@use "area";
@use "pick-win";
@use "restaurant";

//

@use "components/color-classes";
@use "components/typography-extend";
@use "components/layout-extend";

app-root,
app-home,
app-home-header,
app-main,
app-auth,
app-tripleseat,
app-private-events,
app-private-events-desktop,
app-auth-desktop,
app-resy-button,
app-team,
app-about,
app-venue,
app-dates,
app-intro-page,
app-intro-desktop-page,
app-welcome-drink,
app-loader,
app-header,
app-footer,
app-ticket,
app-forums,
app-account,
app-profile,
app-profile-desktop,
app-profile-layout,
app-careers,
app-community,
app-community-desktop app-concierge,
app-area-view,
app-news-item,
app-breadcrumb,
app-contact-us,
app-event-item,
app-base-popup,
app-items-filter,
app-verification,
app-ticket-shared,
app-ticket-details,
app-filter-by-date,
app-my-reservations,
app-ticket-received,
app-community-items,
app-community-items-desktop,
app-account-desktop,
app-ticket-purchased,
app-image-crop-popup,
app-carousel-wrapper,
app-checkout-success,
app-buy-season-ticket,
app-ticket-present-qr,
app-ticket-dates-popup,
app-hear-from-the-pros-page,
app-hear-from-pros,
app-hear-from-the-pros-desktop-page,
app-user-season-ticket,
app-season-ticket-dates,
app-season-ticket-share,
app-received-ticket-page,
app-pick-and-win-history,
app-event-details-desktop,
app-season-ticket-schedule,
app-checkout-season-ticket,
app-season-ticket-schedule,
app-ticket-preview-details,
app-season-ticket-calendar,
app-season-ticket-reservation,
app-season-ticket-reservation-desktop,
app-season-ticket-preview-desktop,
app-season-ticket-area-select,
app-season-ticker-area-details,
app-season-ticket-schedule-desktop-page,
app-season-ticket-area-details,
app-question-leaderboard,
app-predict-and-win,
app-predict-and-win-history,
app-prediction-result-popup,
app-predict-and-win-page,
app-predict-and-win-history-page,
app-question-leaderboard-page,
app-predict-and-win-desktop-page,
app-event-filter-by-category,
app-share-form-and-ticket,
app-season-ticket-item,
app-stripe,
app-season-ticket-stripe-checkout,
app-season-ticket-area-select-desktop-page,
app-season-ticket-area-select-desktop,
app-checkout-season-ticket-page-desktop-page,
app-main-desktop,
app-header-desktop,
app-breadcrumb-desktop,
app-next-ticket,
app-next-ticket-desktop,
app-footer-desktop,
app-buy-season-ticket-desktop,
app-home-desktop,
app-season-ticket-dates-desktop-page,
app-calendar-view-desktop-page,
app-calendar-view-page,
app-login-register-desktop-page,
app-login-register,
app-verification-desktop-page,
app-pick-name,
app-pick-name-desktop-page,
app-checkout-season-ticket-page,
app-question-leaderboard,
app-schedule-layout,
app-question-leaderboard,
app-menu,
app-menu-desktop,
app-restaurant-page,
app-restaurant,
app-restaurant-menu,
app-restaurant-menu-page,
app-question-leaderboard,
app-menu-layout,
app-schedule-mobile-layout,
app-menu-mobile-layout,
app-menu-images-slider,
app-info-desktop,
app-info,
app-static-pages,
app-home-cards-desktop,
app-ticket-events-info,
app-season-tickets-info,
app-vip-memberships-info,
app-ticket-events-info-m,
app-season-tickets-info-m,
app-vip-memberships-info-m,
app-vip-membership-form,
app-general-ticket-form,
app-home-cards,
app-space-desktop,
app-space,
app-privacy,
app-builder-intro,
app-builder-intro-shared,
app-builder-intro-desktop,
app-bankroll-builder,
app-builder-dashboard,
app-builder-my-bets,
app-builder-my-bets-shared,
app-sharp-sports-button,
app-bankroll-builder-desktop,
app-builder-dashboard-desktop,
app-builder-overview-desktop,
app-builder-bets-desktop,
app-overview-summary,
app-builder-overview-home,
app-builder-overview-shared,
app-builder-filter-popup,
app-bet-slip-item,
app-builder-account-select-popup,
app-builder-account-shared,
app-book-select-shared,
app-region-select-shared,
app-builder-today-bets-shared,
app-builder-history-shared,
app-builder-today-bets,
app-builder-account-desktop,
app-builder-leaderboard,
app-builder-leaderboard-desktop,
app-builder-leaderboard-shared,
app-select-venue-shared,
app-select-venue-desktop,
app-clock-count-down,
app-timer,
app-prize,
app-leaderboard,
app-contest-rules-desktop,
app-leaderboard-prizes,
app-select-venue,
app-predict-prize,
app-predict-count-down {
  display: contents;
}
