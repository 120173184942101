:root {
  --account-photo-w: unset;
  --account-photo-aspect: 1.666666666667;
  --account-photo-br: 0;
}

.account {
  &-photo {
    width: var(--account-photo-w);
    max-width: var(--account-photo-w);
    aspect-ratio: var(--account-photo-aspect);
    border-radius: var(--account-photo-br);
    position: relative;
    overflow: hidden;

    &-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 7px;
      border-radius: var(--br-base);
      background-color: var(--bg-c-6);
      font-weight: var(--fw-500);
      font-size: var(--fs-xs);
      line-height: var(--lh-md);
      color: var(--text-c-2);

      &-wrap {
        position: absolute;
        right: var(--padding-h);
        bottom: 8px;
        z-index: 1;
      }
    }
  }

  &-link-block {
    position: relative;
    width: 100%;
    aspect-ratio: 1.725;
    min-height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 8px;
    border-radius: 8px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    &-overlay {
      position: absolute;
      inset: 0;
      z-index: 100;
      border-radius: inherit;
      background-color: rgba(#000, 0.5);
    }

    &--desktop {
      aspect-ratio: 1.611166666667;
      border: 1px solid #4f4f4f;
      border-radius: 16px;
    }

    &-title {
      background: linear-gradient(90deg, #2b2b2b 0%, rgba(43, 43, 43, 0) 100%);
      border-radius: 4px;
      padding: 4px 8px;
      margin-bottom: 8px;
    }

    &-body {
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      flex-grow: 1;

      &-ticket {
        flex: 0 0 160px;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      margin-top: auto;
      padding: 8px;
      border-radius: 8px;
      background: rgba(26, 26, 26, 0.8);
    }

    &-btn {
      margin-top: auto;
      background: linear-gradient(
        270deg,
        rgba(26, 26, 26, 0.8) 0%,
        rgba(26, 26, 26, 0) 100%
      );
      border-radius: 4px;
    }
  }

  &-drink {
    display: flex;
    padding: 8px;
    justify-content: flex-start;
    align-items: stretch;
    background-color: var(--bg-c-3);
    border-radius: 8px;

    &-media {
      width: 64px;
      aspect-ratio: 1;
      border-radius: inherit;
      overflow: hidden;
      margin-right: 16px;
    }
  }

  &--desktop {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    background-color: var(--bg-c-2);
    border-radius: 16px;
    padding: 32px;

    --account-photo-w: 352px;
    --account-photo-aspect: 1;
    --account-photo-br: 16px;
  }

  &-textblock {
    max-width: 480px;
  }

  &-ticket {
    max-width: 387px;
  }
}
