:root {
  --fliptimer-w: 64px;
  --fliptimer-h: 80px;
  --fliptimer-halfh: calc(var(--fliptimer-h) / 2);
  --fliptimer-radius: 4px;
  --fliptimer-fs: 40px;
  --fliptimer-lh: var(--fliptimer-h);
  --fliptimer-slot-fs: var(--fs-sm);
  --fliptimer-slot-lh: var(--lh-sm);
  --fliptimer-m: 9px;
  --fliptimer-stick: 24px;
}

.fliptimer-wrap {
  &,
  * {
    user-select: none;
  }

  &--desktop {
    --fliptimer-w: 80px;
    --fliptimer-h: 94px;
    --fliptimer-halfh: calc(var(--fliptimer-h) / 2);
    --fliptimer-fs: 48px;
    --fliptimer-slot-fs: var(--fs-lg);
    --fliptimer-slot-lh: var(--lh-lg);
    --fliptimer-m: 17px;
    --fliptimer-stick: 40px;
  }
}

@keyframes flipTop {
  0% {
    transform: rotateX(0deg) translateZ(0);
    z-index: 2;
  }
  0%,
  99% {
    opacity: 0.99;
  }
  100% {
    transform: rotateX(-90deg) translateZ(0);
    opacity: 0;
  }
}

@keyframes flipBottom {
  0%,
  50% {
    z-index: -1;
    transform: rotateX(90deg) translateZ(0);
    opacity: 0;
  }
  51% {
    opacity: 0.99;
  }
  100% {
    opacity: 0.99;
    transform: rotateX(0deg) translateZ(0);
    z-index: 5;
  }
}

.flip-clock {
  text-align: center;
  perspective: 400px;
  margin: 0 auto;

  &__piece {
    display: inline-block;
    margin: 0 var(--fliptimer-m);

    &:not(:first-child) {
      .card {
        &:before {
          content: "";
          position: absolute;
          display: block;
          height: var(--fliptimer-stick);
          width: 1px;
          background-color: var(--primary-c-light);
          left: 0;
          top: 50%;
          transform: translate3d(calc(-1 * var(--fliptimer-m)), -50%, 0);
        }
      }
    }
  }

  &__slot {
    font-size: var(--fliptimer-slot-fs);
    line-height: var(--fliptimer-slot-lh);
    font-weight: var(--fw-500);
    color: var(--text-c-3);
  }
}

.card {
  display: block;
  position: relative;
  padding-bottom: var(--fliptimer-halfh);
  font-size: var(--fliptimer-fs);
  line-height: var(--fliptimer-h);
}

.card__top,
.card__bottom,
.card__back::before,
.card__back::after {
  display: block;
  height: var(--fliptimer-halfh);
  line-height: var(--fliptimer-h);
  color: var(--primary-c-light);
  background: var(--bg-c-3);
  padding: 0;
  border-radius: var(--fliptimer-radius) var(--fliptimer-radius) 0 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  width: var(--fliptimer-w);
  transform: translateZ(0);
}

.card__bottom {
  position: absolute;
  top: 50%;
  left: 0;
  border-top: solid 1px var(--bg-c-2);
  color: var(--primary-c-light);
  background: var(--bg-c-3);
  border-radius: 0 0 var(--fliptimer-radius) var(--fliptimer-radius);
  pointer-events: none;
  overflow: hidden;
}

.card__bottom::after {
  display: block;
  margin-top: calc(-1 * (var(--fliptimer-halfh)));
}

.card__back::before,
.card__bottom::after {
  content: attr(data-value);
}

.card__back {
  position: absolute;
  top: 0;
  height: 100%;
  left: 0%;
  pointer-events: none;
  perspective: 230px;
}

.card__back::before {
  position: relative;
  z-index: -1;
  overflow: hidden;
}

.flip .card__back::before {
  animation: flipTop 0.3s cubic-bezier(0.37, 0.01, 0.94, 0.35);
  animation-fill-mode: both;
  transform-origin: center bottom;
}

.flip .card__back .card__bottom {
  transform-origin: center top;
  animation-fill-mode: both;
  animation: flipBottom 0.6s cubic-bezier(0.15, 0.45, 0.28, 1); // 0.3s;
}
