.fs-xxxxl {
  font-size: var(--fs-xxxxl);
}

.fs-xxxl {
  font-size: var(--fs-xxxl);
}

.fs-xxl {
  font-size: var(--fs-xxl);
}

.fs-xl {
  font-size: var(--fs-xl);
}

.fs-lg {
  font-size: var(--fs-lg);
}

.fs-md {
  font-size: var(--fs-md);
}

.fs-sm {
  font-size: var(--fs-sm);
}

.fs-xs {
  font-size: var(--fs-xs);
}

.lh-xxxl {
  line-height: var(--lh-xxxl);
}

.lh-xxl {
  line-height: var(--lh-xxl);
}

.lh-xl {
  line-height: var(--lh-xl);
}

.lh-lg {
  line-height: var(--lh-lg);
}

.lh-md {
  line-height: var(--lh-md);
}

.lh-xs {
  line-height: var(--lh-xs);
}

.lh-xxs {
  line-height: var(--lh-xxs);
}

.lh-xxxs {
  line-height: var(--lh-xxxs);
}

.fw-400 {
  font-weight: var(--fw-400);
}

.fw-500 {
  font-weight: var(--fw-500);
}

.fw-700 {
  font-weight: var(--fw-700);
}

.fw-800 {
  font-weight: var(--fw-700);
}

a {
  text-decoration: none;
}

.underline {
  text-decoration: underline;
}

.text-italic {
  font-style: italic;
}

.text-left,
.ql-align-left {
  text-align: left;
}

.text-center,
.ql-align-center {
  text-align: center;
}

.text-justify,
.ql-align-justify {
  text-align: justify;
}

.text-right,
.ql-align-right {
  text-align: right;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-nowrap {
  white-space: nowrap;
}

.list {
  list-style-position: inside;

  &-roman {
    list-style-type: upper-roman;
  }
}
