:root {
  --user-photo-w: 80px;
}

.user-details {

}

.user-photo {
  &-block {
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--user-photo-w);
    height: var(--user-photo-w);
    border: 2px solid var(--primary-c);
    border-radius: var(--br-base);
    background-color: rgba(var(--bg-c-5-rgb), .5);
    color: rgba(var(--text-c-3-rgb), .5);
    overflow: hidden;
    --icon-w: 50px;
    --icon-h: 50px;
    --icon-fs: 50px;
  }
}
