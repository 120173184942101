.br-tooltip {
  $self: &;
  position: absolute;
  width: 280px;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid var(--primary-c);
  background: var(--bg-c-2);
  font-size: var(--fs-md);
  line-height: var(--lh-md);
  color: var(--text-c-1);
  z-index: 1000;
  pointer-events: none;
  --tooltipY: 8px;
  translate: 0 var(--tooltipY);
  opacity: 0;
  will-change: opacity;
  transition: opacity .3s;

  &--transparent {
    border-radius: 10px;
    border: 1px solid var(--text-c-4);
    background: rgba(0, 0, 0, 0.20);
    backdrop-filter: blur(8px);
  }

  &--bottom-right {
    top: 100%;
    right: 0;
  }

  &--bottom-center {
    top: 100%;
    left: 50%;
    translate: -50% var(--tooltipY);
  }

  &-wrap {
    position: relative;

    &:hover {
      #{$self} {
        opacity: 1;
      }
    }
  }
}
