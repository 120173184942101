:root {
  --btn-mw: 160px;
  --btn-h: 38px;
  --btn-p: 9px 8px;
  --btn-bw: 2px;
  --btn-br: 4px;
  --btn-fw: 500;
  --btn-fs: 14px;
  --btn-lh: 20px;
  --btn-c: var(--text-c-3);
  --btn-icon-w: 32px;
}

.btn-reset {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  outline: none;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  transition: color 0.3s, border-color 0.3s, background-color 0.3s, opacity 0.3s;
  cursor: pointer;

  &:disabled,
  &[disabled],
  &[disabled="disabled"],
  &--disabled {
    color: var(--text-c-1);
    border-color: transparent !important;
    background-color: transparent !important;
    pointer-events: none;
    opacity: 0.5;
  }
}

.btn {
  $self: &;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  min-width: var(--btn-mw);
  height: var(--btn-h);
  padding: var(--btn-p);
  outline: none;
  background-color: transparent;
  border: var(--btn-bw) solid transparent;
  font-family: "IBM Plex Mono", monospace;
  border-radius: var(--btn-br);
  font-size: var(--btn-fs);
  font-weight: var(--btn-fw);
  line-height: var(--btn-lh);
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: color 0.3s, border-color 0.3s, background-color 0.3s, opacity 0.3s;
  cursor: pointer;

  &--auto {
    min-width: unset;
  }

  &--full {
    min-width: unset;
    width: 100%;
    &:disabled,
    &[disabled],
    &[disabled="disabled"],
    &--disabled {
      color: var(--text-c-4) !important;
      border-color: var(--bg-c-4) !important;
      background-color: transparent !important;
    }
  }

  &--white {
    color: var(--text-c-4);
    border-color: var(--bg-c-8);
    background-color: var(--bg-c-8);
  }

  &--gold {
    color: #222222;
    border-color: var(--c-gold);
    background-color: var(--c-gold);
  }

  &--odd {
    min-width: 96px;
  }

  &--primary {
    color: var(--text-c-1);
    border-color: var(--primary-c);
    background-color: var(--primary-c);

    &:hover {
      border-color: var(--primary-c-dark);
      background-color: var(--primary-c-dark);
    }

    &#{$self}--outline {
      color: var(--primary-c);
      border-color: var(--primary-c);
      background-color: rgba(var(--primary-c-dark-rgb), 0);

      &:hover {
        color: var(--primary-c);
        border-color: var(--primary-c);
        background-color: rgba(var(--primary-c-dark-rgb), 0.5);
      }
    }
  }

  &--secondary {
    color: var(--text-c-1);
    border-color: var(--bg-c-5);
    background-color: var(--bg-c-5);

    &:hover {
      border-color: var(--bg-c-7);
      background-color: var(--bg-c-7);
    }

    &#{$self}--outline {
      color: var(--text-c-3);
      border-color: var(--bg-c-7);
      background-color: rgba(var(--primary-c-dark-rgb), 0);

      &:hover {
        color: var(--primary-c);
        border-color: var(--primary-c);
        background-color: rgba(var(--primary-c-dark-rgb), 0.5);
      }
    }

    .page--desktop & {
      &:not(.btn--outline) {
        border-color: var(--bg-c-4);
        background-color: var(--bg-c-4);

        &:hover {
          border-color: var(--bg-c-7);
          background-color: var(--bg-c-7);
        }

        &:active {
          border-color: var(--primary-c-dark);
          background-color: var(--primary-c-dark);
        }
      }
    }
  }

  &--outline {
    &:disabled,
    &[disabled],
    &[disabled="disabled"],
    &--disabled {
      color: var(--text-c-4) !important;
      border-color: var(--bg-c-4) !important;
      background-color: transparent !important;
    }
  }

  &:disabled,
  &[disabled],
  &[disabled="disabled"],
  &--disabled {
    color: var(--text-c-1);
    border-color: var(--bg-c-7);
    background-color: var(--bg-c-7);
    pointer-events: none;
  }

  &--xs {
    --btn-p: 4px 8px;
    --btn-h: 24px;
    --btn-fs: 12px;
    --btn-lh: 16px;
    --btn-mw: auto;
    color: var(--text-c-2);

    &:hover {
      color: var(--text-c-1);
      border-color: transparent;
      background-color: transparent;
    }

    &:disabled,
    &[disabled],
    &[disabled="disabled"],
    &--disabled {
      color: var(--text-c-4);
      border-color: transparent;
      background-color: transparent;
    }
  }

  &-tab {
    $self: &;
    width: 100%;
    flex: 1 0 0;
    min-width: unset;
    border-radius: 0;
    font-weight: var(--fw-700);
    background-color: var(--bg-c-4);
    color: var(--text-c-2);

    &--sm {
      --btn-br: 4px;
      --btn-p: 0 4px;
      --btn-h: 24px;
      --btn-lh: 24px;
      --btn-fs: 14px;
      min-width: 74px;
      font-weight: 400;
      justify-content: center;
      align-items: center;
    }

    &--active {
      color: var(--text-c-1);
      background-color: var(--primary-c);
    }

    &:first-child {
      border-top-left-radius: var(--btn-br);
      border-bottom-left-radius: var(--btn-br);
    }

    &:last-child {
      border-top-right-radius: var(--btn-br);
      border-bottom-right-radius: var(--btn-br);
    }

    &--underline {
      min-width: 140px;
      border: none;
      border-radius: unset;
      border-bottom: 4px solid var(--text-c-4);
      justify-content: center;
      align-items: center;
      font-size: var(--fs-lg);
      line-height: var(--lh-lg);
      font-weight: var(--fw-400);
      color: var(--text-c-2);
      font-family: "Millionaire", sans-serif;
      background-color: transparent;

      &:disabled,
      &[disabled],
      &[disabled="disabled"],
      &--disabled {
        color: var(--text-c-2);
        border-color: transparent !important;
        background-color: transparent !important;
        pointer-events: none;
        opacity: 0.5;
      }

      &#{$self}--active {
        color: var(--text-c-1);
        border-color: var(--primary-c);
        background-color: transparent;
      }

      &:first-child,
      &:last-child {
        border-radius: unset;
      }
    }
  }

  &-nav {
    width: 100%;
    height: 52px;
    flex: 1 0 0;
    border-radius: 0;
    font-size: var(--fs-md);
    line-height: var(--lh-md);
    font-weight: var(--fw-500);
    background-color: var(--bg-c-2);
    color: var(--text-c-2);
    min-width: unset;
    justify-content: center;
    margin-top: 16px;

    &--active {
      color: var(--text-c-1);
      background-color: var(--primary-c);
    }
  }

  &--sm {
    --btn-p: 6px 8px;
    --btn-h: 32px;
    --btn-fs: 12px;
  }

  &--xl {
    --btn-mw: 68px;
    --btn-h: 56px;
    --btn-br: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: var(--fs-sm);
    line-height: var(--lh-md);
    font-weight: var(--fw-500);
    color: var(--text-c-1);
    background-color: var(--bg-c-1);
    white-space: nowrap;
    border: 1px solid var(--text-c-4);

    &--desktop {
      --btn-mw: 160px;
      --btn-h: 100%;
      --btn-br: 12px;
      --icon-w: 48px;
      --icon-h: 48px;
      --icon-fs: 48px;
      background-color: var(--bg-c-1);

      &:hover {
        background-color: var(--bg-c-4);
      }
    }
  }

  &-text {
    display: block;

    &:only-child {
      width: 100%;
    }
  }

  &-icon {
    display: block;

    &:first-child {
      margin-right: 8px;
    }

    &:last-child {
      margin-left: auto;
      padding-left: 8px;
    }
  }

  &--icon {
    --btn-mw: 38px;
    --btn-p: 0px;
    height: var(--btn-mw);
    justify-content: center;
    border: 0;
    background-color: transparent;

    &--sm {
      --btn-mw: 24px;
    }

    .img {
      border-radius: inherit;
    }
  }

  &--social {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    justify-content: center;
    min-width: unset;
    padding: 0;
    color: var(--bg-c-1);
    border-color: var(--primary-c);
    background-color: var(--primary-c);
    --icon-w: 16px;
    --icon-h: 16px;
    --icon-fs: 16px;

    &--xl {
      width: 48px;
      height: 48px;
      --icon-w: 32px;
      --icon-h: 32px;
      --icon-fs: 32px;
    }
  }

  &--filter {
    .btn-text {
      min-width: 40px;
      text-align: left;
    }
  }

  &-counter {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 20px;
    line-height: 20px;
    font-size: var(--fs-xs);
    color: var(--text-c-1);
    font-weight: var(--fw-500);
    background: var(--primary-c);
    border-radius: 10px;
    left: 100%;
    bottom: 100%;
    transform: translate3d(-8px, 8px, 0);
    z-index: 2;

    &-sm {
      min-width: 12px;
      line-height: 12px;
      transform: translate3d(-9px, 8px, 0);
      padding-left: 2px;
      padding-right: 2px;
    }
  }

  &--filter--reset {
    width: 96px;
    max-width: 96px;
    min-width: 96px;
    margin-right: 10px;
  }

  &-square-lg,
  &-square-xl,
  &-square-xxl {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 15px;
  }

  &-square-lg {
    --icon-w: 64px;
    --icon-h: 64px;
    --icon-fs: 64px;
    aspect-ratio: 1.1351351351;
    border-radius: 8px;
    background-color: var(--bg-c-3);

    &:hover {
      background-color: var(--bg-c-2);
    }
  }

  &-square-xl {
    --icon-w: 96px;
    --icon-h: 96px;
    --icon-fs: 96px;
    aspect-ratio: 0.944444444444;
    border-radius: 24px;
    background-color: var(--bg-c-3);

    &:hover {
      background-color: var(--bg-c-2);
    }
  }

  &-square-xxl {
    --icon-w: 154px;
    --icon-h: 154px;
    --icon-fs: 154px;
    aspect-ratio: 1.035460992908;
    border-radius: 24px;
    background-color: var(--bg-c-2);

    &:hover {
      background-color: var(--bg-c-3);
    }
  }
}
