@keyframes filter-popup-show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes filter-popup-toggle {
  0% {
    transform: translate3d(0, 100%, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

.filter {
  &-wrap {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding-bottom: var(--padding-h);
  }

  &-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    &-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 8px;
      border-radius: 4px;
      margin-bottom: 8px;
      transition: background-color .3s;
      cursor: pointer;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-icon {
      margin-left: auto;
    }
  }

  &-footer {
    position: sticky;
    left: 0;
    bottom: 0;
    z-index: 3;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: var(--padding-h) 0;
    margin: auto 0 calc(-1 * var(--padding-h)) 0;
  }

  &-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 16px 16px;
    padding-bottom: 16px;

    &--result {
      grid-column: 1/-1;
    }
  }

  &-popup {
    background-color: rgba(#000, .8);
    height: 100%;
    overflow: hidden;
    padding-top: 84px;
    animation: filter-popup-show linear .3s forwards;

    &--desktop {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 100;
    }

    .filter-popup-in {
      max-height: 100%;
      overflow-y: auto;
      background-color: var(--bg-c-1);
      border-radius: 16px 16px 0 0;
      transform: translate3d(0, 100%, 0);
      animation: filter-popup-toggle linear .3s forwards;
      animation-delay: .3s;

      &-title {

      }
    }
  }
}
