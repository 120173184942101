@keyframes toggleOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.welcome-anim {
  &-wrap {
    opacity: 0;
    justify-content: center;
    align-items: stretch;
    animation: toggleOpacity linear 1s forwards;
    animation-delay: 1s;
  }

  &-title {
    opacity: 0;
    animation: toggleOpacity linear 1s forwards;
    animation-delay: 2s;
  }

  &-logo {
    opacity: 0;
    animation: toggleOpacity linear 1s forwards;
    animation-delay: 3s;
  }

  &-address {
    opacity: 0;
    animation: toggleOpacity linear 1s forwards;
    animation-delay: 5s;
  }
}
