:root {
  --events-calendar-p: 0;
  --events-calendar-br: 2px;
  --events-calendar-gap: 1px;
  --events-calendar-day-fs: var(--fs-md);
  --events-calendar-day-lh: var(--lh-lg);
  --events-calendar-day-justify: center;
  --events-calendar-date-fs: var(--fs-md);
  --events-calendar-date-lh: var(--lh-lg);
  --events-calendar-item-p: 2px;
  --events-calendar-item-m: 8px;
  --events-calendar-item-fs: var(--fs-xs);
  --events-calendar-item-lh: var(--lh-xs);
  --events-calendar-item-fw: var(--fw-500);
  --events-calendar-item-time-fs: var(--events-calendar-item-fs);
  --events-calendar-item-time-lh: var(--events-calendar-item-lh);
  --events-calendar-item-time-fw: var(--fw-500);
  --events-calendar-item-time-m: 2px;
  --events-calendar-empty-fs: var(--fs-xs);
  --events-calendar-empty-lh: var(--lh-xs);
  --events-calendar-empty-fw: var(--fw-500);
}

.events {
  &-coming-soon {
    ~ * {
      display: none !important;
    }
  }

  &-wrap {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 0 var(--padding-h) var(--padding-h);
  }

  &-header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: var(--padding-h) 0 var(--padding-h);
    background-color: var(--bg-c-2);
    z-index: 10;

    &-in {
      margin-top: var(--padding-h);
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  &-list {
    $self: &;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    --events-list-title-top: 241px;

    + #{$self} {
      margin-top: 24px;
    }

    &--desktop {
      --events-list-title-top: 56px;

      #{$self}-item {
        margin-top: 0;
        height: 100%;
      }

      + #{$self}--desktop {
        margin-top: 16px;
      }
    }

    &-title {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      font-size: var(--fs-lg);
      line-height: var(--lh-lg);
      font-weight: var(--fw-700);
      color: var(--text-c-1);
      padding-bottom: 8px;
      position: sticky;
      top: var(--events-list-title-top);
      background-color: var(--bg-c-2);
      z-index: 8;

      + #{$self}-item,
      + app-event-item #{$self}-item {
        margin-top: 0;
      }
    }

    &-item {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      padding: var(--padding-h);
      background: var(--bg-c-3);
      border-radius: var(--br-base);
      margin-top: 8px;

      &-header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      &-date {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
        padding: 4px;
        flex: 0 0 48px;
        max-width: 48px;
        width: 48px;
        height: 64px;
        background: var(--bg-c-2);
        border-radius: 4px;
      }

      &-day {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3px 4px;
        height: 20px;
        background: #4d4d4d;
        border-radius: 2px;
        font-size: var(--fs-xs);
        font-weight: var(--fw-500);
        line-height: var(--lh-xs);
        color: var(--text-c-2);
        text-transform: uppercase;
      }

      &-num {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: var(--fs-xl);
        font-weight: var(--fw-800);
        line-height: var(--lh-xl);
        color: var(--primary-c);
      }

      &-info {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        padding: 0 0 8px 8px;
      }

      &-media {
        display: flex;
        flex: 0 0 88px;
        max-width: 88px;
        height: 64px;
        margin-left: auto;
        border-radius: 4px;
        overflow: hidden;
      }

      &-content {
        margin-top: 8px;
        height: calc(2 * var(--lh-md));

        &,
        > p {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          max-height: calc(2 * var(--lh-md));
          word-break: break-all;
          overflow: hidden;
        }

        > p {
          font-size: inherit;
          line-height: inherit;

          &,
          * {
            background-color: transparent !important;
          }
        }
      }

      &-footer {
        display: flex;
        flex-direction: row-reverse;
        margin-top: 4px;
      }

      &-category {
        min-width: 120px;
        text-align: center;
        border-radius: 4px;
        background: var(--bg-c-5);
        padding-left: 10px;
        padding-right: 10px;

        &-wrap {
          justify-content: space-between;
        }
      }
    }

    &--community {
      #{$self}-item {
        position: relative;
        padding: 16px 376px 16px 16px;
        min-height: 160px;

        &-media {
          position: absolute;
          right: 16px;
          top: 16px;
          width: 200px;
          max-width: 200px;
          height: 128px;
          margin: 0;
          border-radius: 8px;
        }

        &-content {
          &,
          > p {
            margin-top: 0;
            height: calc(2 * var(--lh-lg));
            max-height: calc(2 * var(--lh-lg));
          }
        }

        &-category {
          &-wrap {
            justify-content: flex-start;
          }
        }
      }
    }
  }

  &-inner {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    --events-inner-media-w: 100%;

    &--desktop {
      --events-inner-media-w: 320px;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;

      .events-inner-btn {
        position: static;
        left: unset;
        bottom: unset;
        margin-bottom: unset;
        padding-bottom: 0;
        margin-left: auto;
        min-width: 160px;
      }

      .landing-tickets {
        width: 320px;
      }
    }

    &-media {
      width: var(--events-inner-media-w);
      aspect-ratio: 1.3745;
      border-radius: var(--br-base);
      overflow: hidden;
    }

    &-video {
      width: var(--events-inner-media-w);
      position: relative;
      aspect-ratio: 1.4375;
      border-radius: var(--br-base);
      overflow: hidden;

      //&:before {
      //  content: "";
      //  position: absolute;
      //  top: 0;
      //  right: 0;
      //  bottom: 0;
      //  left: 0;
      //  border-radius: inherit;
      //  z-index: 1;
      //  background: rgba(#000, .5);
      //}

      &-btn {
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 2;
        transform: translate3d(-50%, -50%, 0);
        --icon-w: 56px;
        --icon-h: 56px;
        --icon-fs: 56px;
      }
    }

    &-ticket {
      .landing-ticket {
        $ticket: ".landing-ticket";

        &-cutout {
          &-1 {
            stroke: var(--bg-c-5);
            fill: var(--bg-c-2);
          }

          &-2 {
            fill: var(--bg-c-5);
          }
        }

        &-shadow {
          &-1 {
            #{unquote($ticket)}-cutout {
              &-1 {
                stroke: var(--bg-c-5);
                fill: var(--bg-c-3);
              }

              &-2 {
                fill: var(--bg-c-5);
              }
            }
          }

          &-2 {
            #{unquote($ticket)}-cutout {
              &-1 {
                stroke: var(--bg-c-5);
                fill: var(--bg-c-4);
              }

              &-2 {
                fill: var(--bg-c-5);
              }
            }
          }
        }
      }
    }

    &-content {
      &--desktop {
        max-width: calc(100% - var(--events-inner-media-w));
      }

      img {
        display: block;
        max-width: 100%;
        height: auto;
        margin-top: 8px;
        margin-bottom: 8px;
      }

      * {
        font-size: inherit;
        line-height: inherit;
        background-color: transparent !important;
      }

      a {
        color: var(--primary-c) !important;
      }
    }

    &-btn {
      position: sticky;
      left: 0;
      bottom: 0;
      padding: var(--padding-h) 0;
      margin-bottom: calc(-1 * var(--padding-h));
      margin-top: auto;
      z-index: 3;
    }

    &-footer {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      width: 100%;
    }
  }

  &-calendar {
    $self: &;

    &--desktop {
      --events-calendar-p: 6px;
      --events-calendar-br: 4px;
      --events-calendar-gap: 4px;
      --events-calendar-day-fs: var(--fs-lg);
      --events-calendar-day-lh: var(--lh-lg);
      --events-calendar-day-fw: var(--fw-700);
      --events-calendar-day-justify: flex-start;
      --events-calendar-date-fs: var(--fs-md);
      --events-calendar-date-lh: var(--lh-lg);
      --events-calendar-item-p: 8px;
      --events-calendar-item-fs: var(--fs-md);
      --events-calendar-item-lh: var(--lh-lg);
      --events-calendar-item-fw: var(--fw-700);
      --events-calendar-item-time-fs: var(--fs-sm);
      --events-calendar-item-time-lh: var(--lh-md);
      --events-calendar-item-time-fw: var(--fw-500);
      --events-calendar-item-time-m: 4px;
      --events-calendar-empty-fs: var(--fs-md);
      --events-calendar-empty-lh: var(--lh-lg);
      --events-calendar-empty-fw: var(--fw-500);

      #{$self}-header {
        --events-calendar-day-h: 40px;
        text-indent: var(--events-calendar-p);
        --events-calendar-day-fw: var(--fw-700);
      }

      #{$self}-body {
        --events-calendar-day-h: 48px;
        --events-calendar-day-fs: var(--fs-xl);
        --events-calendar-day-lh: var(--lh-xl);
        --events-calendar-day-fw: var(--fw-800);
      }
    }

    &--filter-row {
      left: 0;
      top: 56px;
    }

    &-header,
    &-body {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr;
      grid-column-gap: var(--events-calendar-gap);
      grid-row-gap: var(--events-calendar-gap);
    }

    &-header {
      --events-calendar-day-c: var(--text-c-3);
      --events-calendar-day-h: 32px;
      --events-calendar-day-fw: var(--fw-500);
      text-transform: uppercase;
    }

    &-body {
      --events-calendar-day-c: var(--primary-c);
      --events-calendar-day-h: 32px;
      --events-calendar-day-fw: var(--fw-700);
    }

    &-day {
      display: flex;
      justify-content: var(--events-calendar-day-justify);
      align-items: center;
      font-size: var(--events-calendar-day-fs);
      font-weight: var(--events-calendar-day-fw);
      line-height: var(--events-calendar-day-lh);
      height: var(--events-calendar-day-h);
      color: var(--text-c-1);

      &-wrap {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        background-color: var(--bg-c-3);
        overflow: hidden;
        padding: 0 var(--events-calendar-p) var(--events-calendar-p)
          var(--events-calendar-p);
      }

      &-text {
        min-width: var(--events-calendar-day-lh);
        padding: 0 3px;
        border-radius: var(--events-calendar-br);
        text-align: center;
      }

      &-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
      }
    }

    &-item {
      flex-direction: column;
      align-items: stretch;
      padding: var(--events-calendar-item-p);
      background-color: var(--bg-c-4);
      border-radius: var(--events-calendar-br);
      text-align: left;
      margin-bottom: var(--events-calendar-item-m);

      &:last-child {
        margin-bottom: 0;
      }

      &-title {
        font-size: var(--events-calendar-item-fs);
        line-height: var(--events-calendar-item-lh);
        font-weight: var(--events-calendar-item-fw);
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        max-height: calc(var(--events-calendar-item-lh) * 2);
        word-break: break-word;
        overflow: hidden;
        color: var(--primary-c);
      }

      &-time {
        font-size: var(--events-calendar-item-time-fs);
        line-height: var(--events-calendar-item-time-lh);
        font-weight: var(--events-calendar-item-time-fw);
        margin-top: var(--events-calendar-item-time-m);
        color: var(--text-c-2);
      }
    }

    &-empty {
      margin-top: auto;
      margin-bottom: auto;
      text-align: center;
      font-size: var(--events-calendar-empty-fs);
      line-height: var(--events-calendar-empty-lh);
      font-weight: var(--events-calendar-empty-fw);
      color: var(--primary-c);
    }
  }
}
