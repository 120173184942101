$icomoon-font-family: "bankroll-iconset" !default;
$icomoon-font-path: "~/src/bankroll-iconset/fonts" !default;

$bicon-add-user: "\e900";
$bicon-add-user-o: "\e901";
$bicon-apple: "\e902";
$bicon-apple-o: "\e903";
$bicon-arrow-down: "\e904";
$bicon-arrow-down-2: "\e905";
$bicon-arrow-down-2-outlined: "\e906";
$bicon-arrow-down-3: "\e907";
$bicon-arrow-down-3-outlined: "\e908";
$bicon-arrow-down-o: "\e909";
$bicon-arrow-left: "\e90a";
$bicon-arrow-left-2: "\e90b";
$bicon-arrow-left-2-o: "\e90c";
$bicon-arrow-left-3: "\e90d";
$bicon-arrow-left-3-o: "\e90e";
$bicon-arrow-left-o: "\e90f";
$bicon-arrow-right: "\e910";
$bicon-arrow-right-2: "\e911";
$bicon-arrow-right-2-o: "\e912";
$bicon-arrow-right-3: "\e913";
$bicon-arrow-right-3-o: "\e914";
$bicon-arrow-right-o: "\e915";
$bicon-arrow-up: "\e916";
$bicon-arrow-up-2: "\e917";
$bicon-arrow-up-2-o: "\e918";
$bicon-arrow-up-3: "\e919";
$bicon-arrow-up-3-o: "\e91a";
$bicon-arrow-up-o: "\e91b";
$bicon-bankroll-ticket: "\e91d";
$bicon-bullet-point: "\e91e";
$bicon-bullet-point-o: "\e91f";
$bicon-calendar: "\e920";
$bicon-calendar-o: "\e921";
$bicon-call: "\e922";
$bicon-call-missed: "\e923";
$bicon-call-missed-o: "\e924";
$bicon-call-o: "\e925";
$bicon-call-silent: "\e926";
$bicon-call-silent-o: "\e927";
$bicon-calling: "\e928";
$bicon-calling-o: "\e929";
$bicon-camera: "\e9e8";
$bicon-camera-o: "\e9e7";
$bicon-chart: "\e92a";
$bicon-chart-o: "\e92b";
$bicon-check: "\e92c";
$bicon-check-o: "\e92d";
$bicon-close: "\e92e";
$bicon-close-square: "\e92f";
$bicon-close-square-o: "\e930";
$bicon-community: "\e91c";
$bicon-copy: "\e933";
$bicon-copy-o: "\e934";
$bicon-danger: "\e935";
$bicon-danger-o: "\e936";
$bicon-delete: "\e937";
$bicon-delete-o: "\e938";
$bicon-document: "\e939";
$bicon-document-o: "\e93a";
$bicon-down-circle: "\e93b";
$bicon-down-circle-o: "\e93c";
$bicon-down-square: "\e93d";
$bicon-down-square-o: "\e93e";
$bicon-download: "\e93f";
$bicon-download-o: "\e940";
$bicon-edit: "\e941";
$bicon-edit-o: "\e942";
$bicon-edit-square: "\e943";
$bicon-edit-square-o: "\e944";
$bicon-facebook: "\e945";
$bicon-facebook-o: "\e946";
$bicon-fail: "\e947";
$bicon-fail-o: "\e948";
$bicon-filter: "\e949";
$bicon-filter-2: "\e94a";
$bicon-filter-2-o: "\e94b";
$bicon-filter-o: "\e94c";
$bicon-food: "\e94d";
$bicon-game-emoji: "\e94f";
$bicon-game-emoji-o: "\e950";
$bicon-google: "\e951";
$bicon-google-o: "\e952";
$bicon-hide: "\e953";
$bicon-hide-o: "\e954";
$bicon-home: "\e955";
$bicon-home-o: "\e956";
$bicon-icons-24px-o: "\e957";
$bicon-info-circle: "\e958";
$bicon-info-circle-o: "\e959";
$bicon-info-square: "\e95a";
$bicon-info-square-o: "\e95b";
$bicon-instagram: "\e95c";
$bicon-instagram-o: "\e95d";
$bicon-leaderboard: "\e95e";
$bicon-leaderboard-o: "\e95f";
$bicon-left-circle: "\e960";
$bicon-left-circle-o: "\e961";
$bicon-left-square: "\e962";
$bicon-left-square-o: "\e963";
$bicon-linkedin: "\e964";
$bicon-linkedin-o: "\e965";
$bicon-location: "\e966";
$bicon-location-arrow: "\e94e";
$bicon-location-arrow-o: "\e976";
$bicon-location-o: "\e967";
$bicon-lock: "\e968";
$bicon-lock-o: "\e969";
$bicon-logout: "\e96a";
$bicon-logout-o: "\e96b";
$bicon-magic: "\e931";
$bicon-menu: "\e96c";
$bicon-menu-o: "\e96d";
$bicon-message: "\e96e";
$bicon-message-o: "\e96f";
$bicon-minus: "\e970";
$bicon-minus-o: "\e971";
$bicon-money: "\e972";
$bicon-money-o: "\e973";
$bicon-more-circle: "\e974";
$bicon-more-circle-o: "\e975";
$bicon-more-square: "\e9b8";
$bicon-mores-quare-o: "\e977";
$bicon-my-bets: "\e978";
$bicon-my-bets-o: "\e979";
$bicon-notification: "\e97a";
$bicon-notification-o: "\e97b";
$bicon-offer: "\e97c";
$bicon-offer-o: "\e97d";
$bicon-paper: "\e97e";
$bicon-paper-download: "\e97f";
$bicon-paper-download-o: "\e980";
$bicon-paper-fail: "\e981";
$bicon-paper-fail-o: "\e982";
$bicon-paper-negative: "\e983";
$bicon-paper-negative-o: "\e984";
$bicon-paper-o: "\e985";
$bicon-paper-plus: "\e986";
$bicon-paper-plus-o: "\e987";
$bicon-paper-upload: "\e988";
$bicon-paper-upload-o: "\e989";
$bicon-password: "\e98a";
$bicon-password-o: "\e98b";
$bicon-photo: "\e98c";
$bicon-photo-o: "\e98d";
$bicon-play: "\e98e";
$bicon-play-o: "\e98f";
$bicon-plus: "\e990";
$bicon-plus-o: "\e991";
$bicon-profile: "\e992";
$bicon-user: "\e992";
$bicon-profile-o: "\e993";
$bicon-user-o: "\e993";
$bicon-reward-o: "\e995";
$bicon-right-circle: "\e996";
$bicon-right-circle-o: "\e997";
$bicon-right-square: "\e998";
$bicon-right-square-o: "\e999";
$bicon-search: "\e99a";
$bicon-search-o: "\e99b";
$bicon-settings: "\e99c";
$bicon-settings-o: "\e99d";
$bicon-share: "\e99e";
$bicon-share-o: "\e99f";
$bicon-shield-done: "\e9a0";
$bicon-shield-fail: "\e9a1";
$bicon-shield-fail-o: "\e9a2";
$bicon-shield-sone-o: "\e9a3";
$bicon-show: "\e9a4";
$bicon-show-o: "\e9a5";
$bicon-small-square: "\e9a6";
$bicon-small-square-o: "\e9a7";
$bicon-sport-all: "\e9a8";
$bicon-sport-aussie-rules: "\e9a9";
$bicon-sport-badminton: "\e9aa";
$bicon-sport-baseball: "\e9ac";
$bicon-sport-basketball: "\e9ad";
$bicon-sport-card: "\e9ae";
$bicon-sport-clover: "\e9af";
$bicon-sport-corner: "\e9b0";
$bicon-sport-cricket: "\e9b1";
$bicon-sport-diamonds: "\e9b2";
$bicon-sport-flag: "\e9b3";
$bicon-sport-football: "\e9ba";
$bicon-sport-futsal: "\e9b4";
$bicon-sport-golf: "\e9e9";
$bicon-sport-handball: "\e9b5";
$bicon-sport-hearts: "\e9b6";
$bicon-sport-ice-hockey: "\e9b7";
$bicon-sport-hockey: "\e9b7";
$bicon-sport-pikes: "\e9b9";
$bicon-sport-rugby-union: "\e9bb";
$bicon-sport-soccer: "\e9bc";
$bicon-sport-table-tennis: "\e9bd";
$bicon-sport-tennis: "\e9be";
$bicon-sport-volleyball: "\e9bf";
$bicon-square: "\e9c0";
$bicon-square-money: "\e9c1";
$bicon-square-money-o: "\e9c2";
$bicon-square-o: "\e9c3";
$bicon-star: "\e9c4";
$bicon-star-o: "\e9c5";
$bicon-success: "\e9c6";
$bicon-success-o: "\e9c7";
$bicon-swap-o: "\e9c8";
$bicon-telegram: "\e9c9";
$bicon-telegram-o: "\e9ca";
$bicon-tick-square: "\e9cb";
$bicon-tick-square-o: "\e9cc";
$bicon-ticket: "\e932";
$bicon-time-circle: "\e9cd";
$bicon-time-circle-o: "\e9ce";
$bicon-time-square: "\e9cf";
$bicon-time-square-o: "\e9d0";
$bicon-twitter: "\e9d1";
$bicon-twitter-o: "\e9d2";
$bicon-unlock: "\e9d3";
$bicon-unlock-o: "\e9d4";
$bicon-up-circle: "\e9d5";
$bicon-up-circle-o: "\e9d6";
$bicon-up-square: "\e9d7";
$bicon-up-square-o: "\e9d8";
$bicon-upload: "\e9d9";
$bicon-upload-o: "\e9da";
$bicon-video: "\e9db";
$bicon-video-o: "\e9dc";
$bicon-volume-down: "\e9dd";
$bicon-volume-down-o: "\e9de";
$bicon-volume-off: "\e9df";
$bicon-volume-off-o: "\e9e0";
$bicon-volume-up: "\e9e1";
$bicon-volume-up-o: "\e9e2";
$bicon-wallet: "\e9e3";
$bicon-wallet-o: "\e9e4";
$bicon-whatsapp: "\e9e5";
$bicon-whatsapp-o: "\e9e6";

