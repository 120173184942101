.crop-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  aspect-ratio: 1;
  border: 2px solid var(--primary-c);
  border-radius: var(--br-base);
  background: rgba(#b8b8bb, 0.16);
  overflow: hidden;
}
