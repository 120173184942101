:root {
  --lb-row-h: 48px;
  --pos: translateY(100%);
}

.leaderboard {
  $self: &;

  &-wrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    &--desktop {
      --lb-row-h: 64px;

      &#{$self}-wrap--short {
        #{$self}-col {
          border-radius: 8px;
          margin-right: 6px;

          &:nth-child(1) {
            width: 72px;
            padding-left: 16px;
            border-radius: 8px;
          }

          &:nth-child(2) {
            padding-left: 40px;
          }

          &:nth-child(3) {
            width: 100px;
          }
        }
      }
    }

    &--screen {
      --lb-row-h: 72px;

      &#{$self}-wrap--short {
        #{$self}-col {
          margin-right: 8px;
        }
      }
    }

    &--short {
      #{$self}-row {
        &--highlight {
          #{$self}-col {
            &:nth-child(1),
            &:nth-child(2) {
              --leaderboard-col-bg: var(--primary-c);
            }

            &:nth-child(3) {
              --leaderboard-col-bg: var(--bg-c-3);
            }
          }
        }
      }

      #{$self}-col {
        margin-right: 4px;

        &:nth-child(1) {
          width: 58px;
          padding-left: 10px;
          --leaderboard-col-bg: var(--bg-c-4);
          --leaderboard-col-deg: 20deg;
          --leaderboard-col-x: -8px;
        }

        &:nth-child(2) {
          flex: 1;
          margin-left: -16px;
          padding-left: 27px;
          --leaderboard-col-bg: var(--bg-c-3);
          --leaderboard-col-deg: 20deg;
          --leaderboard-col-x: 8px;
          overflow: hidden;
        }

        &:nth-child(3) {
          flex: unset;
          margin-left: unset;
          padding-left: unset;
          margin-right: 0;
          width: 72px;
          align-items: center;
          --leaderboard-col-bg: var(--bg-c-3);
          --leaderboard-col-deg: 0deg;
          --leaderboard-col-x: 0px;
        }
      }
    }
  }

  &-row {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    height: var(--lb-row-h);

    &--highlight {
      #{$self}-col {
        &:nth-child(1),
        &:nth-child(3) {
          --leaderboard-col-bg: var(--primary-c);
        }
      }
    }

    &--screen {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transition: transform 0.3s ease-in-out;
      transform: translateY(calc(var(--pos) * 100%));
      z-index: 1;
    }
  }

  &-col {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-radius: 4px;
    margin-right: 2px;
    isolation: isolate;
    overflow: hidden;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-color: var(--leaderboard-col-bg);
      transform: translate3d(var(--leaderboard-col-x), 0, 0)
        skew(var(--leaderboard-col-deg));
      border-radius: inherit;
      z-index: -1;
    }

    &-media {
      width: 32px;
      aspect-ratio: 1;
      border-radius: 4px;
      overflow: hidden;
    }

    &-count {
      min-width: 30px;
    }

    &:nth-child(1) {
      width: 58px;
      padding-left: 10px;
      --leaderboard-col-bg: var(--bg-c-3);
      --leaderboard-col-deg: 20deg;
      --leaderboard-col-x: -8px;
    }

    &:nth-child(2) {
      overflow: visible;
      width: 66px;
      padding-left: 9px;
      --leaderboard-col-bg: var(--bg-c-4);
      --leaderboard-col-deg: 20deg;
      --leaderboard-col-x: -8px;
    }

    &:nth-child(3) {
      flex: 1;
      margin-left: -16px;
      padding-left: 27px;
      --leaderboard-col-bg: var(--bg-c-3);
      --leaderboard-col-deg: 20deg;
      --leaderboard-col-x: 8px;
    }

    &:nth-child(4) {
      background-color: var(--bg-c-4);
      margin-right: 0;
      width: 72px;
      align-items: center;

      &:before {
        display: none;
      }
    }
  }
}

.location-popup {
  padding: 48px 24px;
  background-color: var(--bg-c-2);
}
