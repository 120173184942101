.br-collapse {
  $self: &;

  display: grid;
  grid-template-rows: 0fr;
  grid-template-columns: 1fr;
  transition: grid-template-rows 0.3s;

  &-in {
    overflow: hidden;
  }

  &-label {
    #{$self}-icon-up {
      display: none;
    }
  }

  &-input {
    &:checked {
      ~ #{$self} {
        grid-template-rows: 1fr;
      }

      ~ #{$self}-label {
        #{$self}-icon-up {
          display: flex;
        }

        #{$self}-icon-down {
          display: none;
        }
      }
    }
  }
}
