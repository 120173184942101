.header,
.menu-wrap {
  .resy {
    &,
    * {
      position: absolute !important;
      display: block;
      width: 100% !important;
      height: 100% !important;
      left: 0 !important;
      top: 0 !important;
      pointer-events: all !important;
      z-index: 2 !important;
      opacity: 0 !important;
    }
  }
}

.restaurant-wrap,
.main,
.resy-parent {
  .resy-wrap {
    display: table;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    svg {
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: auto;
      fill: #fff;
      z-index: 1;
    }

    .resy {
      position: relative;
      display: block;

      iframe {
        opacity: 0;
      }
    }
  }
}

.resy-wrap {
  &--desktop {
    .resy {
      span, iframe {
        width: 128px!important;
        height: 32px!important;
      }
    }
  }

  &--mobile {
    .resy {
      span, iframe {
        width: 68px!important;
        height: 18px!important;
      }
    }
  }
}
