:root {
  --padding-h: 15px;
  --br-base: 8px;
  --br-base-sm: 4px;
  --br-base-md: 12px;
  --br-base-lg: 16px;
}

html,
body {
  width: 100%;
  height: 100%;
  min-height: 100%;
}

body {
  //background-color: var(--bg-c-2);
}

.page {
  position: relative;
  width: 100%;
  min-height: 100%;

  &--desktop {
    min-width: 1280px;
    padding-top: 10px;
  }
}

.logo {
  display: block;
  width: auto;
  height: 20px;

  &--lg {
    height: 54px;
  }

  &--desktop {
    height: 64px;
  }
}

.wrap {
  flex: 1;
  padding: var(--padding-h) var(--padding-h) 0 var(--padding-h);

  &--desktop {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    padding-top: 0;
    padding-bottom: 8px;
    overflow: hidden;
  }
}

.no-scroll {
  overflow: hidden !important;
}

.negative-subnav {
  margin-top: calc(-1 * var(--padding-h));
}

.padding-v {
  padding-top: var(--padding-h);
  padding-bottom: var(--padding-h);
}

.padding-h {
  padding-left: var(--padding-h);
  padding-right: var(--padding-h);
}

.neg-padding-h {
  margin-left: calc(-1 * var(--padding-h));
  margin-right: calc(-1 * var(--padding-h));
}

.sticky {
  position: sticky;
  z-index: 8;
  left: 0;

  &--subnav {
    margin-top: calc(-1 * var(--padding-h));
    padding-top: var(--padding-h);
    top: var(--subnav-h);
  }

  &--top {
    top: 0;
  }

  &--bottom {
    margin-top: auto;
    bottom: 0;
  }
}

.wrap-btns {
  position: sticky;
  bottom: 0;
  left: 0;
  margin-top: var(--padding-h);
  padding: var(--padding-h);
  z-index: 10;
}

.container {
  flex: 1 0 0;
  width: 100%;
  max-width: 1240px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;

  &-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-sm {
    flex: 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }

  &-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 8px;
    grid-row-gap: 0;
  }

  &-1 {
    grid-template-columns: 1fr;
  }

  &-1-1 {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 24px;
  }

  &-1-2 {
    grid-template-columns: 1fr 2fr;
  }

  &-1-1-2 {
    grid-template-columns: 320px 320px 1fr;
  }

  &-1-1-1 {
    grid-template-columns: 1fr 1fr 1fr;
  }

  &-1-2-1 {
    grid-template-columns: 320px 1fr 320px;
  }

  &-1-3 {
    grid-template-columns: 320px 1fr;
  }

  &-3-1 {
    grid-template-columns: 2fr 440px;
  }

  &-2-1-1 {
    grid-template-columns: 1fr 320px 320px;
  }

  &--events {
    transition: all 0.2s linear 0.2s;
  }
}

.col-gap {
  grid-column-gap: 8px;
}

.row-gap {
  grid-row-gap: 8px;
}

.col-gap-sm {
  grid-column-gap: 1px;
}

.col-no-gap {
  gap: unset;
}

.col {
  height: 100%;
  overflow: hidden;

  .popup {
    display: contents;
  }

  &,
  &-body,
  &-in {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }

  &-header {
    height: 150px;
    flex: 0 0 64px;
    padding: 0 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-body {
    padding: 16px 0;
    background-color: var(--bg-c-2);
    overflow: hidden;
    border-radius: 8px;

    &-in {
      position: relative;
      padding: 0 12px 0 16px;
      overflow-x: hidden;
      overflow-y: scroll;
      min-height: 100%;
      flex-grow: 1;

      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        background-color: var(--bg-c-2); /* or add it to the track */
      }

      /* Add a thumb */
      &::-webkit-scrollbar-thumb {
        background: var(--bg-c-5);
      }
    }
  }

  &-empty {
    --icon-w: 80px;
    --icon-h: 80px;
    --icon-fs: 80px;
  }
}

.br-base {
  border-radius: var(--br-base);
}

.br-base-sm {
  border-radius: var(--br-base-sm);
}

.br-base-md {
  border-radius: var(--br-base-md);
}

.br-base-lg {
  border-radius: var(--br-base-lg);
}





