:root {
  --subnav-h: 40px;
  --subnav-item-h: 24px;
  --subnav-item-gap: 8px;
}

.subnav {
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 var(--padding-h);
  height: var(--subnav-h);
  min-height: var(--subnav-h);
  z-index: 9;

  &-back {
    margin-right: 8px;
  }

  &-menu {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &-item {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: var(--subnav-item-h);
    color: var(--text-c-3);
    font-size: var(--fs-md);
    line-height: var(--lh-lg);
    font-weight: var(--fw-500);

    &--active {
      color: var(--text-c-1);
    }

    &:not(:first-child) {
      margin-left: var(--subnav-item-gap);
      padding-left: var(--subnav-item-gap);
      border-left: 1px solid currentColor;
    }
  }

  &-close {
    margin-left: auto;
  }

  &-bc-wrap {
    flex: 1;
    height: 100%;
    position: relative;
    overflow-x: auto;
    direction: rtl;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

app-ng-dynamic-breadcrumb {
  display: contents;
}

.custom-bread-crumb {
  display: flex;
  flex-direction: row-reverse;
  background-color: transparent !important;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
  min-width: 100%;
  width: min-content;
  height: 100%;
  padding: 0 !important;

  > span {
    display: contents;

    &:not(:first-child) {
      li {
        margin-left: var(--subnav-item-gap);
        padding-left: var(--subnav-item-gap);
        border-left: 1px solid var(--text-c-3);
      }
    }

    &:last-child {
      li {
        color: var(--text-c-1) !important;
        margin-right: auto;
      }
    }
  }

  .line {
    display: none;
  }

  li, a {
    display: block !important;
    white-space: nowrap;
    min-height: var(--subnav-item-h);
    font-size: var(--fs-md) !important;
    line-height: var(--subnav-item-h) !important;
    font-weight: var(--fw-500) !important;
  }

  li {
    color: var(--text-c-3) !important;
  }

  a {
    color: inherit !important;
  }

  a:hover {
    text-decoration: none;
  }
}
