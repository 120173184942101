
:root{
  --dot-w: 12px;
  --dot-distance: 15px
}

.dot-flashing {
  position: relative;
  width: var(--dot-w);
  height: var(--dot-w);
  border-radius: 5px;
  background-color: var(--text-c-2);
  color: var(--text-c-2);
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
  &-sm{
    --dot-w: 8px;
  }
}
.dot-flashing::before, .dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -30px;
  width: var(--dot-w);
  height: var(--dot-w);
  border-radius: 5px;
  background-color: var(--text-c-3);
  color: var(--text-c-1);
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;

}
.dot-flashing::after {
  left: 30px;
  width: var(--dot-w);
  height: var(--dot-w);
  border-radius: 5px;
  background-color: var(--text-c-3);
  color: var(--text-c-1);
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}
@keyframes dot-flashing {
  0% {
    background-color: var(--text-c-3);
  }
  50%, 100% {
    background-color: var(--text-c-1);
  }
}
