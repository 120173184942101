@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?3mpypn') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?3mpypn') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?3mpypn##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.bicon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bicon-add-user {
  &:before {
    content: $bicon-add-user; 
  }
}
.bicon-add-user-o {
  &:before {
    content: $bicon-add-user-o; 
  }
}
.bicon-apple {
  &:before {
    content: $bicon-apple; 
  }
}
.bicon-apple-o {
  &:before {
    content: $bicon-apple-o; 
  }
}
.bicon-arrow-down {
  &:before {
    content: $bicon-arrow-down; 
  }
}
.bicon-arrow-down-2 {
  &:before {
    content: $bicon-arrow-down-2; 
  }
}
.bicon-arrow-down-2-outlined {
  &:before {
    content: $bicon-arrow-down-2-outlined; 
  }
}
.bicon-arrow-down-3 {
  &:before {
    content: $bicon-arrow-down-3; 
  }
}
.bicon-arrow-down-3-outlined {
  &:before {
    content: $bicon-arrow-down-3-outlined; 
  }
}
.bicon-arrow-down-o {
  &:before {
    content: $bicon-arrow-down-o; 
  }
}
.bicon-arrow-left {
  &:before {
    content: $bicon-arrow-left; 
  }
}
.bicon-arrow-left-2 {
  &:before {
    content: $bicon-arrow-left-2; 
  }
}
.bicon-arrow-left-2-o {
  &:before {
    content: $bicon-arrow-left-2-o; 
  }
}
.bicon-arrow-left-3 {
  &:before {
    content: $bicon-arrow-left-3; 
  }
}
.bicon-arrow-left-3-o {
  &:before {
    content: $bicon-arrow-left-3-o; 
  }
}
.bicon-arrow-left-o {
  &:before {
    content: $bicon-arrow-left-o; 
  }
}
.bicon-arrow-right {
  &:before {
    content: $bicon-arrow-right; 
  }
}
.bicon-arrow-right-2 {
  &:before {
    content: $bicon-arrow-right-2; 
  }
}
.bicon-arrow-right-2-o {
  &:before {
    content: $bicon-arrow-right-2-o; 
  }
}
.bicon-arrow-right-3 {
  &:before {
    content: $bicon-arrow-right-3; 
  }
}
.bicon-arrow-right-3-o {
  &:before {
    content: $bicon-arrow-right-3-o; 
  }
}
.bicon-arrow-right-o {
  &:before {
    content: $bicon-arrow-right-o; 
  }
}
.bicon-arrow-up {
  &:before {
    content: $bicon-arrow-up; 
  }
}
.bicon-arrow-up-2 {
  &:before {
    content: $bicon-arrow-up-2; 
  }
}
.bicon-arrow-up-2-o {
  &:before {
    content: $bicon-arrow-up-2-o; 
  }
}
.bicon-arrow-up-3 {
  &:before {
    content: $bicon-arrow-up-3; 
  }
}
.bicon-arrow-up-3-o {
  &:before {
    content: $bicon-arrow-up-3-o; 
  }
}
.bicon-arrow-up-o {
  &:before {
    content: $bicon-arrow-up-o; 
  }
}
.bicon-bankroll-ticket {
  &:before {
    content: $bicon-bankroll-ticket; 
  }
}
.bicon-bullet-point {
  &:before {
    content: $bicon-bullet-point; 
  }
}
.bicon-bullet-point-o {
  &:before {
    content: $bicon-bullet-point-o; 
  }
}
.bicon-calendar {
  &:before {
    content: $bicon-calendar; 
  }
}
.bicon-calendar-o {
  &:before {
    content: $bicon-calendar-o; 
  }
}
.bicon-call {
  &:before {
    content: $bicon-call; 
  }
}
.bicon-call-missed {
  &:before {
    content: $bicon-call-missed; 
  }
}
.bicon-call-missed-o {
  &:before {
    content: $bicon-call-missed-o; 
  }
}
.bicon-call-o {
  &:before {
    content: $bicon-call-o; 
  }
}
.bicon-call-silent {
  &:before {
    content: $bicon-call-silent; 
  }
}
.bicon-call-silent-o {
  &:before {
    content: $bicon-call-silent-o; 
  }
}
.bicon-calling {
  &:before {
    content: $bicon-calling; 
  }
}
.bicon-calling-o {
  &:before {
    content: $bicon-calling-o; 
  }
}
.bicon-camera {
  &:before {
    content: $bicon-camera; 
  }
}
.bicon-camera-o {
  &:before {
    content: $bicon-camera-o; 
  }
}
.bicon-chart {
  &:before {
    content: $bicon-chart; 
  }
}
.bicon-chart-o {
  &:before {
    content: $bicon-chart-o; 
  }
}
.bicon-check {
  &:before {
    content: $bicon-check; 
  }
}
.bicon-check-o {
  &:before {
    content: $bicon-check-o; 
  }
}
.bicon-close {
  &:before {
    content: $bicon-close; 
  }
}
.bicon-close-square {
  &:before {
    content: $bicon-close-square; 
  }
}
.bicon-close-square-o {
  &:before {
    content: $bicon-close-square-o; 
  }
}
.bicon-community {
  &:before {
    content: $bicon-community; 
  }
}
.bicon-copy {
  &:before {
    content: $bicon-copy; 
  }
}
.bicon-copy-o {
  &:before {
    content: $bicon-copy-o; 
  }
}
.bicon-danger {
  &:before {
    content: $bicon-danger; 
  }
}
.bicon-danger-o {
  &:before {
    content: $bicon-danger-o; 
  }
}
.bicon-delete {
  &:before {
    content: $bicon-delete; 
  }
}
.bicon-delete-o {
  &:before {
    content: $bicon-delete-o; 
  }
}
.bicon-document {
  &:before {
    content: $bicon-document; 
  }
}
.bicon-document-o {
  &:before {
    content: $bicon-document-o; 
  }
}
.bicon-down-circle {
  &:before {
    content: $bicon-down-circle; 
  }
}
.bicon-down-circle-o {
  &:before {
    content: $bicon-down-circle-o; 
  }
}
.bicon-down-square {
  &:before {
    content: $bicon-down-square; 
  }
}
.bicon-down-square-o {
  &:before {
    content: $bicon-down-square-o; 
  }
}
.bicon-download {
  &:before {
    content: $bicon-download; 
  }
}
.bicon-download-o {
  &:before {
    content: $bicon-download-o; 
  }
}
.bicon-edit {
  &:before {
    content: $bicon-edit; 
  }
}
.bicon-edit-o {
  &:before {
    content: $bicon-edit-o; 
  }
}
.bicon-edit-square {
  &:before {
    content: $bicon-edit-square; 
  }
}
.bicon-edit-square-o {
  &:before {
    content: $bicon-edit-square-o; 
  }
}
.bicon-facebook {
  &:before {
    content: $bicon-facebook; 
  }
}
.bicon-facebook-o {
  &:before {
    content: $bicon-facebook-o; 
  }
}
.bicon-fail {
  &:before {
    content: $bicon-fail; 
  }
}
.bicon-fail-o {
  &:before {
    content: $bicon-fail-o; 
  }
}
.bicon-filter {
  &:before {
    content: $bicon-filter; 
  }
}
.bicon-filter-2 {
  &:before {
    content: $bicon-filter-2; 
  }
}
.bicon-filter-2-o {
  &:before {
    content: $bicon-filter-2-o; 
  }
}
.bicon-filter-o {
  &:before {
    content: $bicon-filter-o; 
  }
}
.bicon-food {
  &:before {
    content: $bicon-food; 
  }
}
.bicon-game-emoji {
  &:before {
    content: $bicon-game-emoji; 
  }
}
.bicon-game-emoji-o {
  &:before {
    content: $bicon-game-emoji-o; 
  }
}
.bicon-google {
  &:before {
    content: $bicon-google; 
  }
}
.bicon-google-o {
  &:before {
    content: $bicon-google-o; 
  }
}
.bicon-hide {
  &:before {
    content: $bicon-hide; 
  }
}
.bicon-hide-o {
  &:before {
    content: $bicon-hide-o; 
  }
}
.bicon-home {
  &:before {
    content: $bicon-home; 
  }
}
.bicon-home-o {
  &:before {
    content: $bicon-home-o; 
  }
}
.bicon-icons-24px-o {
  &:before {
    content: $bicon-icons-24px-o; 
  }
}
.bicon-info-circle {
  &:before {
    content: $bicon-info-circle; 
  }
}
.bicon-info-circle-o {
  &:before {
    content: $bicon-info-circle-o; 
  }
}
.bicon-info-square {
  &:before {
    content: $bicon-info-square; 
  }
}
.bicon-info-square-o {
  &:before {
    content: $bicon-info-square-o; 
  }
}
.bicon-instagram {
  &:before {
    content: $bicon-instagram; 
  }
}
.bicon-instagram-o {
  &:before {
    content: $bicon-instagram-o; 
  }
}
.bicon-leaderboard {
  &:before {
    content: $bicon-leaderboard; 
  }
}
.bicon-leaderboard-o {
  &:before {
    content: $bicon-leaderboard-o; 
  }
}
.bicon-left-circle {
  &:before {
    content: $bicon-left-circle; 
  }
}
.bicon-left-circle-o {
  &:before {
    content: $bicon-left-circle-o; 
  }
}
.bicon-left-square {
  &:before {
    content: $bicon-left-square; 
  }
}
.bicon-left-square-o {
  &:before {
    content: $bicon-left-square-o; 
  }
}
.bicon-linkedin {
  &:before {
    content: $bicon-linkedin; 
  }
}
.bicon-linkedin-o {
  &:before {
    content: $bicon-linkedin-o; 
  }
}
.bicon-location {
  &:before {
    content: $bicon-location; 
  }
}
.bicon-location-arrow {
  &:before {
    content: $bicon-location-arrow; 
  }
}
.bicon-location-arrow-o {
  &:before {
    content: $bicon-location-arrow-o; 
  }
}
.bicon-location-o {
  &:before {
    content: $bicon-location-o; 
  }
}
.bicon-lock {
  &:before {
    content: $bicon-lock; 
  }
}
.bicon-lock-o {
  &:before {
    content: $bicon-lock-o; 
  }
}
.bicon-logout {
  &:before {
    content: $bicon-logout; 
  }
}
.bicon-logout-o {
  &:before {
    content: $bicon-logout-o; 
  }
}
.bicon-magic {
  &:before {
    content: $bicon-magic; 
  }
}
.bicon-menu {
  &:before {
    content: $bicon-menu; 
  }
}
.bicon-menu-o {
  &:before {
    content: $bicon-menu-o; 
  }
}
.bicon-message {
  &:before {
    content: $bicon-message; 
  }
}
.bicon-message-o {
  &:before {
    content: $bicon-message-o; 
  }
}
.bicon-minus {
  &:before {
    content: $bicon-minus; 
  }
}
.bicon-minus-o {
  &:before {
    content: $bicon-minus-o; 
  }
}
.bicon-money {
  &:before {
    content: $bicon-money; 
  }
}
.bicon-money-o {
  &:before {
    content: $bicon-money-o; 
  }
}
.bicon-more-circle {
  &:before {
    content: $bicon-more-circle; 
  }
}
.bicon-more-circle-o {
  &:before {
    content: $bicon-more-circle-o; 
  }
}
.bicon-more-square {
  &:before {
    content: $bicon-more-square; 
  }
}
.bicon-mores-quare-o {
  &:before {
    content: $bicon-mores-quare-o; 
  }
}
.bicon-my-bets {
  &:before {
    content: $bicon-my-bets; 
  }
}
.bicon-my-bets-o {
  &:before {
    content: $bicon-my-bets-o; 
  }
}
.bicon-notification {
  &:before {
    content: $bicon-notification; 
  }
}
.bicon-notification-o {
  &:before {
    content: $bicon-notification-o; 
  }
}
.bicon-offer {
  &:before {
    content: $bicon-offer; 
  }
}
.bicon-offer-o {
  &:before {
    content: $bicon-offer-o; 
  }
}
.bicon-paper {
  &:before {
    content: $bicon-paper; 
  }
}
.bicon-paper-download {
  &:before {
    content: $bicon-paper-download; 
  }
}
.bicon-paper-download-o {
  &:before {
    content: $bicon-paper-download-o; 
  }
}
.bicon-paper-fail {
  &:before {
    content: $bicon-paper-fail; 
  }
}
.bicon-paper-fail-o {
  &:before {
    content: $bicon-paper-fail-o; 
  }
}
.bicon-paper-negative {
  &:before {
    content: $bicon-paper-negative; 
  }
}
.bicon-paper-negative-o {
  &:before {
    content: $bicon-paper-negative-o; 
  }
}
.bicon-paper-o {
  &:before {
    content: $bicon-paper-o; 
  }
}
.bicon-paper-plus {
  &:before {
    content: $bicon-paper-plus; 
  }
}
.bicon-paper-plus-o {
  &:before {
    content: $bicon-paper-plus-o; 
  }
}
.bicon-paper-upload {
  &:before {
    content: $bicon-paper-upload; 
  }
}
.bicon-paper-upload-o {
  &:before {
    content: $bicon-paper-upload-o; 
  }
}
.bicon-password {
  &:before {
    content: $bicon-password; 
  }
}
.bicon-password-o {
  &:before {
    content: $bicon-password-o; 
  }
}
.bicon-photo {
  &:before {
    content: $bicon-photo; 
  }
}
.bicon-photo-o {
  &:before {
    content: $bicon-photo-o; 
  }
}
.bicon-play {
  &:before {
    content: $bicon-play; 
  }
}
.bicon-play-o {
  &:before {
    content: $bicon-play-o; 
  }
}
.bicon-plus {
  &:before {
    content: $bicon-plus; 
  }
}
.bicon-plus-o {
  &:before {
    content: $bicon-plus-o; 
  }
}
.bicon-profile {
  &:before {
    content: $bicon-profile; 
  }
}
.bicon-user {
  &:before {
    content: $bicon-user; 
  }
}
.bicon-profile-o {
  &:before {
    content: $bicon-profile-o; 
  }
}
.bicon-user-o {
  &:before {
    content: $bicon-user-o; 
  }
}
.bicon-reward-o {
  &:before {
    content: $bicon-reward-o; 
  }
}
.bicon-right-circle {
  &:before {
    content: $bicon-right-circle; 
  }
}
.bicon-right-circle-o {
  &:before {
    content: $bicon-right-circle-o; 
  }
}
.bicon-right-square {
  &:before {
    content: $bicon-right-square; 
  }
}
.bicon-right-square-o {
  &:before {
    content: $bicon-right-square-o; 
  }
}
.bicon-search {
  &:before {
    content: $bicon-search; 
  }
}
.bicon-search-o {
  &:before {
    content: $bicon-search-o; 
  }
}
.bicon-settings {
  &:before {
    content: $bicon-settings; 
  }
}
.bicon-settings-o {
  &:before {
    content: $bicon-settings-o; 
  }
}
.bicon-share {
  &:before {
    content: $bicon-share; 
  }
}
.bicon-share-o {
  &:before {
    content: $bicon-share-o; 
  }
}
.bicon-shield-done {
  &:before {
    content: $bicon-shield-done; 
  }
}
.bicon-shield-fail {
  &:before {
    content: $bicon-shield-fail; 
  }
}
.bicon-shield-fail-o {
  &:before {
    content: $bicon-shield-fail-o; 
  }
}
.bicon-shield-sone-o {
  &:before {
    content: $bicon-shield-sone-o; 
  }
}
.bicon-show {
  &:before {
    content: $bicon-show; 
  }
}
.bicon-show-o {
  &:before {
    content: $bicon-show-o; 
  }
}
.bicon-small-square {
  &:before {
    content: $bicon-small-square; 
  }
}
.bicon-small-square-o {
  &:before {
    content: $bicon-small-square-o; 
  }
}
.bicon-sport-all {
  &:before {
    content: $bicon-sport-all; 
  }
}
.bicon-sport-aussie-rules {
  &:before {
    content: $bicon-sport-aussie-rules; 
  }
}
.bicon-sport-badminton {
  &:before {
    content: $bicon-sport-badminton; 
  }
}
.bicon-sport-baseball {
  &:before {
    content: $bicon-sport-baseball; 
  }
}
.bicon-sport-basketball {
  &:before {
    content: $bicon-sport-basketball; 
  }
}
.bicon-sport-card {
  &:before {
    content: $bicon-sport-card; 
  }
}
.bicon-sport-clover {
  &:before {
    content: $bicon-sport-clover; 
  }
}
.bicon-sport-corner {
  &:before {
    content: $bicon-sport-corner; 
  }
}
.bicon-sport-cricket {
  &:before {
    content: $bicon-sport-cricket; 
  }
}
.bicon-sport-diamonds {
  &:before {
    content: $bicon-sport-diamonds; 
  }
}
.bicon-sport-flag {
  &:before {
    content: $bicon-sport-flag; 
  }
}
.bicon-sport-football {
  &:before {
    content: $bicon-sport-football; 
  }
}
.bicon-sport-futsal {
  &:before {
    content: $bicon-sport-futsal; 
  }
}
.bicon-sport-golf {
  &:before {
    content: $bicon-sport-golf; 
  }
}
.bicon-sport-handball {
  &:before {
    content: $bicon-sport-handball; 
  }
}
.bicon-sport-hearts {
  &:before {
    content: $bicon-sport-hearts; 
  }
}
.bicon-sport-ice-hockey {
  &:before {
    content: $bicon-sport-ice-hockey; 
  }
}
.bicon-sport-hockey {
  &:before {
    content: $bicon-sport-hockey; 
  }
}
.bicon-sport-pikes {
  &:before {
    content: $bicon-sport-pikes; 
  }
}
.bicon-sport-rugby-union {
  &:before {
    content: $bicon-sport-rugby-union; 
  }
}
.bicon-sport-soccer {
  &:before {
    content: $bicon-sport-soccer; 
  }
}
.bicon-sport-table-tennis {
  &:before {
    content: $bicon-sport-table-tennis; 
  }
}
.bicon-sport-tennis {
  &:before {
    content: $bicon-sport-tennis; 
  }
}
.bicon-sport-volleyball {
  &:before {
    content: $bicon-sport-volleyball; 
  }
}
.bicon-square {
  &:before {
    content: $bicon-square; 
  }
}
.bicon-square-money {
  &:before {
    content: $bicon-square-money; 
  }
}
.bicon-square-money-o {
  &:before {
    content: $bicon-square-money-o; 
  }
}
.bicon-square-o {
  &:before {
    content: $bicon-square-o; 
  }
}
.bicon-star {
  &:before {
    content: $bicon-star; 
  }
}
.bicon-star-o {
  &:before {
    content: $bicon-star-o; 
  }
}
.bicon-success {
  &:before {
    content: $bicon-success; 
  }
}
.bicon-success-o {
  &:before {
    content: $bicon-success-o; 
  }
}
.bicon-swap-o {
  &:before {
    content: $bicon-swap-o; 
  }
}
.bicon-telegram {
  &:before {
    content: $bicon-telegram; 
  }
}
.bicon-telegram-o {
  &:before {
    content: $bicon-telegram-o; 
  }
}
.bicon-tick-square {
  &:before {
    content: $bicon-tick-square; 
  }
}
.bicon-tick-square-o {
  &:before {
    content: $bicon-tick-square-o; 
  }
}
.bicon-ticket {
  &:before {
    content: $bicon-ticket; 
  }
}
.bicon-time-circle {
  &:before {
    content: $bicon-time-circle; 
  }
}
.bicon-time-circle-o {
  &:before {
    content: $bicon-time-circle-o; 
  }
}
.bicon-time-square {
  &:before {
    content: $bicon-time-square; 
  }
}
.bicon-time-square-o {
  &:before {
    content: $bicon-time-square-o; 
  }
}
.bicon-twitter {
  &:before {
    content: $bicon-twitter; 
  }
}
.bicon-twitter-o {
  &:before {
    content: $bicon-twitter-o; 
  }
}
.bicon-unlock {
  &:before {
    content: $bicon-unlock; 
  }
}
.bicon-unlock-o {
  &:before {
    content: $bicon-unlock-o; 
  }
}
.bicon-up-circle {
  &:before {
    content: $bicon-up-circle; 
  }
}
.bicon-up-circle-o {
  &:before {
    content: $bicon-up-circle-o; 
  }
}
.bicon-up-square {
  &:before {
    content: $bicon-up-square; 
  }
}
.bicon-up-square-o {
  &:before {
    content: $bicon-up-square-o; 
  }
}
.bicon-upload {
  &:before {
    content: $bicon-upload; 
  }
}
.bicon-upload-o {
  &:before {
    content: $bicon-upload-o; 
  }
}
.bicon-video {
  &:before {
    content: $bicon-video; 
  }
}
.bicon-video-o {
  &:before {
    content: $bicon-video-o; 
  }
}
.bicon-volume-down {
  &:before {
    content: $bicon-volume-down; 
  }
}
.bicon-volume-down-o {
  &:before {
    content: $bicon-volume-down-o; 
  }
}
.bicon-volume-off {
  &:before {
    content: $bicon-volume-off; 
  }
}
.bicon-volume-off-o {
  &:before {
    content: $bicon-volume-off-o; 
  }
}
.bicon-volume-up {
  &:before {
    content: $bicon-volume-up; 
  }
}
.bicon-volume-up-o {
  &:before {
    content: $bicon-volume-up-o; 
  }
}
.bicon-wallet {
  &:before {
    content: $bicon-wallet; 
  }
}
.bicon-wallet-o {
  &:before {
    content: $bicon-wallet-o; 
  }
}
.bicon-whatsapp {
  &:before {
    content: $bicon-whatsapp; 
  }
}
.bicon-whatsapp-o {
  &:before {
    content: $bicon-whatsapp-o; 
  }
}

