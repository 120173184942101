.nav {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  max-width: 510px;
  background-color: var(--bg-c-2);

  &--desktop {
    max-width: unset;
  }
}
