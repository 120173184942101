:root {
  --area-details-media-w: calc(100% - 18px);
  --area-details-media-p: 6px;
  --area-details-media-transform: translate3d(21px, 0, 0);
  --area-details-media-transform-selected: translate3d(0, 0, 0);
  --area-details-media-transform-next: translate3d(-21px, 0, 0);
}

.area-details {
  &--desktop {
    --area-details-media-w: 100%;
    --area-details-media-p: 0;
    --area-details-media-transform: translate3d(0, 0, 0);
    --area-details-media-transform-selected: translate3d(0, 0, 0);
    --area-details-media-transform-next: translate3d(0, 0, 0);
  }

  &-media {
    overflow: hidden;

    &-slider {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: nowrap;
      transition: transform .3s;
    }

    &-item {
      $self: &;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      aspect-ratio: 1.97361;
      flex-shrink: 0;
      padding-left: var(--area-details-media-p);
      padding-right: var(--area-details-media-p);

      img {
        max-width: var(--area-details-media-w);
        transition: transform .3s;
        transform: var(--area-details-media-transform);
      }

      &--active {
        img {
          transform: var(--area-details-media-transform-selected);
        }

        ~ #{$self} img {
          transform: var(--area-details-media-transform-next);
        }
      }
    }
  }

  &-dots {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-in {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-arrow {
      width: 38px;
      height: 38px;

      &:disabled,
      &[disabled],
      &[disabled="disabled"] {
        opacity: 0;
      }
    }
  }

  &-dot {
    --bg-dot: rgba(var(--bg-c-5-rgb), 50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 14px;
    height: 14px;

    &--active {
      --bg-dot: var(--primary-c);
    }

    &:before {
      content: "";
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: var(--bg-dot);
      transition: .3s background-color;
    }
  }

}
