.sports-menu {
  &-item {
    justify-content: flex-start;
    align-items: center;
    color: var(--text-c-3);
    background-color: var(--bg-c-3);
    margin-top: 8px;
    pointer-events: all;
    --icon-w: 24px;
    --icon-h: 24px;
    --icon-fs: 24px;

    &:hover {
      background-color: var(--sports-item-c);
      color: rgba(#fff, .8);
    }

    &--active {
      background-color: var(--sports-item-c);
      color: #fff;
      position: sticky;
      top: 0;
      left: 0;
      z-index: 7;
      pointer-events: none;
    }

    &:first-child {
      margin-top: 0;
    }
  }
}
