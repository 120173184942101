.info-block {
  padding: 8px;
  border-radius: var(--br-base);
  background-color: var(--bg-c-3);

  &-title {
    margin: 0 0 8px 0;
    font-size: var(--fs-lg);
    font-weight: var(--fw-800);
    line-height: var(--lh-lg);
    color: var(--text-c-1);
  }
}

.info-page-content {
  > * {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    background-color: transparent;
  }

  a {
    color: var(--primary-c) !important;
  }
}
