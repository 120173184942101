:root {
  --season-month-header-fs: var(--fs-lg);
  --season-month-header-lh: var(--lh-lg);
}

.season {
  $self: &;
  &-calendar {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    &--desktop {
      --season-month-header-fs: var(--fs-md);
      --season-month-header-lh: var(--lh-lg);

      #{$self}-month {
        &-week {
          --season-day-fs: var(--fs-sm);
        }

        &-body {
          --season-day-fs: var(--fs-md);
          --season-day-mh: 28px;
          --season-day-lh: 28px;
        }

        &-week, &-body {
          grid-template-columns: repeat(7, 30px);
        }
      }
    }
  }

  &-month {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: var(--padding-h);
    background-color: var(--bg-c-3);
    box-shadow: 0 10px 60px rgba(#000, 0.1);
    border-radius: var(--br-base);

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 15px;
      font-size: var(--season-month-header-fs);
      line-height: var(--season-month-header-lh);

      &-name {
        display: block;
        font-weight: var(--fw-700);
        color: var(--text-c-1);
      }

      &-year {
        display: block;
        font-weight: var(--fw-500);
        color: var(--text-c-3);
      }
    }

    &-cols {
      display: grid;
      grid-template-columns: repeat(5, auto);
      grid-template-rows: repeat(1, 1fr);
      justify-content: space-between;
      align-items: center;
      grid-gap: 8px 10px;
    }

    &-col {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: nowrap;
      color: var(--text-c-3);
      font-size: calc(var(--fs-xs) - 2px);
      line-height: var(--lh-lg);
      font-weight: var(--fw-500);
      text-transform: uppercase;

      span {
        display: block;
        color: var(--c-success);
        font-size: var(--fs-md);
        font-weight: var(--fw-700);
        line-height: inherit;
        padding-right: 4px;
      }
    }

    &-week,
    &-body {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(7, 32px);
      grid-template-rows: repeat(1, 1fr);
      justify-content: space-between;
      align-items: center;
    }

    &-week {
      height: 16px;
      --season-day-fw: var(--fw-500);
      --season-day-fs: var(--fs-md);
      --season-day-lh: 16px;
      --season-day-mh: 16px;
      --season-day-color: var(--text-c-3);
      text-transform: uppercase;
    }

    &-body {
      --season-day-fw: var(--fw-500);
      --season-day-fs: var(--fs-lg);
      --season-day-lh: var(--lh-lg);
      --season-day-mh: 32px;
      --season-day-color: var(--text-c-2);
      padding-top: 15px;
      grid-row-gap: 15px;
    }

    &-day {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: var(--season-day-mh);
      text-align: center;
      border: 1px solid transparent;
      border-radius: 4px;
      font-weight: var(--fw-500);
      font-size: var(--season-day-fs);
      line-height: var(--season-day-lh);
      color: var(--season-day-color);

      &.highlighted {
        cursor: pointer;
        --season-day-color: var(--text-c-1);
        border-color: var(--c-success);
      }

      &.selected {
        --season-day-color: var(--text-c-4);
        border-color: var(--c-success);
        background-color: var(--c-success);
      }
    }
  }
}
