.tabs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  max-width: 510px;
  padding: var(--padding-h);
  column-gap: 1px;

  &--desktop {
    max-width: unset;

    .btn-tab {
      min-width: 120px;
    }
  }
}

